import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';
import { usePersistedDataStore, useLocalDataStore } from '../store.js';
import {useNavigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';

const CompNavigation = ({ comps, handleUpdateStateCallback, globalCompRef }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
    const getCompSheet = usePersistedDataStore((state) => state.compSheet);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
    const comp = parseInt(searchParams.get('comp')) - 1 || 0;
    // These indices are set when a filter is applied, use them to restrict the # of comps you display, and to update the comp index to correct number.
    const getFilterIndexes = usePersistedDataStore((state) => state.filterIndexes);
    const [inputValue, setInputValue] = useState(null);
    const compSheetLength = useMemo(() => 
        getCompSheet.filter(item => item !== null && item !== undefined), [getCompSheet]).length;
    const getIsFetching = usePersistedDataStore((state) => state.isFetching);
    
    // Memoized derived value that uses inputValue if it exists and is not "1"
    // This fixed the jumpy useeffect loading in of comp #
    const derivedValue = useMemo(() => {
        if (getFilterIndexes) {
            const compIndex = getFilterIndexes.findIndex(index => index === comp);
            if (compIndex !== -1) {
                return (compIndex + 1).toString();
            }
        }
        return (comp + 1).toString(); // Fallback to comp + 1
    }, [getFilterIndexes, comp]);
    // Idk that the setter and stream processor invocation is neceessary in this function, but is lightweight so nbd.
    const fetchIteration = usePersistedDataStore((state) => state.fetchIteration);



    // Handle input change from InputBase (debounced)
    const handleInputChange = async (event) => {
        const value = event.target.value;
        setInputValue(value);
        
        if (debounceTimeout) clearTimeout(debounceTimeout);

        const timeout = setTimeout(() => {
            const numValue = parseInt(value, 10);
            if (isNaN(numValue)) {
                const blankTimeout = setTimeout(() => {
                    setInputValue((comp).toString());
                }, 1000);
                setDebounceTimeout(blankTimeout);
                return;
            }

            // If number is zero or negative, go to first case
            if (numValue <= 0) {
                setInputValue("1");
                searchParams.set('comp', 1);
                navigate(`${location.pathname}?${searchParams.toString()}`);
                return;
            }

            // If number is larger than total cases, go to last case
            if (numValue > getNegotiationObj.cases.length) {
                const lastIndex = getNegotiationObj.cases.length;
                setInputValue(lastIndex.toString());
                searchParams.set('comp', lastIndex);
                navigate(`${location.pathname}?${searchParams.toString()}`);
                return;
            }

            // Rest of validation logic
            if (!getNegotiationObj.cases[numValue - 1]) {
                toast.error('That comp is not available.');
                setInputValue((comp + 1).toString());
                return;
            }

            if (value === "1") {
                setInputValue(value);
                searchParams.set('comp', 1);
                navigate(`${location.pathname}?${searchParams.toString()}`);
                return;
            }
            
            if (getFilterIndexes) {
                if(getFilterIndexes?.length !== getNegotiationObj.cases.length){
                    console.log('in this??')
                    if(numValue>getFilterIndexes.length){
                        console.log(getFilterIndexes[getFilterIndexes.length-1]+1)
                        searchParams.set('comp', getFilterIndexes[getFilterIndexes.length-1]+1);
                    }else if(numValue<1){
                        searchParams.set('comp', getFilterIndexes[0]+1);
                        setInputValue(1)
                    }else{
                        searchParams.set('comp', getFilterIndexes[numValue-1]+1);
                    }
                }
            } else if (numValue < 1) {
                setInputValue(1)
                searchParams.set('comp', 1);
            } else if (numValue > getCompSheet.length) {
                if (getIsFetching) {
                    toast.error('Next comp loading, try again later.');
                    setInputValue((comp + 1).toString());
                    return;
                }
                setInputValue(getCompSheet.length)
                searchParams.set('comp', getCompSheet.length);
            } else {
                searchParams.set('comp', numValue);
            }
            navigate(`${location.pathname}?${searchParams.toString()}`);
        }, 300);

        setDebounceTimeout(timeout);
    };

    // REMMEBER YOUR 'comp' is the true index in this component. stupid way to do this differentlyt han others lol
    const handlePrev = useCallback(() => {
        const url = new URL(location.pathname + location.search, window.location.origin);
        const searchParams = new URLSearchParams(url.search);
    
        if (getReviewPage) {
            setReviewPage(false);
        } else if (comp === 0) {
            return; // Don't do anything if at the beginning of the list.
        } else if (getFilterIndexes) {
            const compIndex = getFilterIndexes.findIndex(index => index === comp);
    
            if (compIndex > 0) {
                searchParams.set('comp', getFilterIndexes[compIndex - 1] + 1);
            } else {
                return; // The filter indexes back route.
            }
        } else {
            searchParams.set('comp', comp);
        }
    
        // Invoke the function to compare ref and set state.
        handleUpdateStateCallback();
    
        const newUrlString = `${url.pathname}?${searchParams.toString()}`;
    
        if (inputValue) {
            setInputValue(null);
        }
    
        navigate(newUrlString);
    }, [location, getReviewPage, setReviewPage, comp, getFilterIndexes, handleUpdateStateCallback, inputValue, navigate]);

    const handleNext = useCallback(() => {
        const url = new URL(location.pathname + location.search, window.location.origin);
        const searchParams = new URLSearchParams(url.search);
        const comp = parseInt(searchParams.get('comp'));

        // If we're at the last comp, set review page to true
        if (comp >= getNegotiationObj.cases.length) {
            setReviewPage(true);
            return;
        }

        // Check if next comp exists
        if (!getNegotiationObj.cases[comp]) {
            if (getIsFetching) {
                toast.error('Next comp loading, try again later.');
            }
            return;
        }

        if (comp < getCompSheet.length) {
            if (getFilterIndexes) {
                const compIndex = getFilterIndexes.findIndex(index => index === comp - 1);

                if (compIndex === getFilterIndexes.length - 1) {
                    setReviewPage(true);
                    return;
                }

                searchParams.set('comp', getFilterIndexes[compIndex + 1] + 1);
            } else {
                searchParams.set('comp', comp + 1);
            }

            if (inputValue) {
                setInputValue(null);
            }

            const newUrlString = `${url.pathname}?${searchParams.toString()}`;
            navigate(newUrlString);
        }
    }, [
        location, 
        getCompSheet, 
        getFilterIndexes, 
        setReviewPage, 
        inputValue, 
        navigate, 
        getNegotiationObj,
        getIsFetching
    ]);

    // Can put hover state for the background being gray, but I don't love how the icon looks rounded w/ too large of a bg atm.
    return (
        <div className="flex items-center justify-end">
            <button
                onClick={handlePrev}
                // update to disabled for the filtered index version.
                disabled={comp === 0 || getFilterIndexes && getFilterIndexes.findIndex(index => index === comp) === 0}
                className="flex hover:text-blue-500 disabled:hover:text-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-transparent"
            >
                <ChevronLeftIcon />
            </button>

            {/* MUI InputBase Field for entering the index with minimal styling */}
            <InputBase
                value={inputValue?inputValue:derivedValue}
                onChange={handleInputChange}
                type="text"
                inputProps={{
                    style: {
                        textAlign: 'center',
                        padding: '0',
                        width: `${derivedValue.length+1}ch`, // Dynamic width based on character length
                        minWidth: '25px', // Set a minimum width
                        maxWidth: '40px', // Optional max width to prevent it from being too large
                    },
                }}
                className=" text-lg border-none focus:outline-none"
                style={{ fontSize: '1rem', textAlign: 'center', padding: '0', width: 'auto' }} // Remove border and custom width
            />

            <span className="text-lg min-w-[30px]">/ {getFilterIndexes?getFilterIndexes.length: getNegotiationObj.cases.length}</span>

            {getIsFetching?
            <div className="flex items-center justify-center ml-1">
                <CircularProgress size={18} sx={{ color: 'gray' }}/>
            </div>
            :null}

            <button
                onClick={handleNext}
                // This disabled state actually never gets triggered (which is desired behavior)
                disabled={currentIndex === getCompSheet.length}
                className="flex items-center py-1 pl-1 text-black transition-colors duration-300 
                    hover:text-blue-500 disabled:hover:text-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-transparent"
            >
                <ChevronRightIcon />
            </button>
        </div>
    );
};

export default CompNavigation;

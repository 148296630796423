import React, { memo, useMemo } from 'react';
import { 
  DataGridPro, 
  GridToolbarContainer, 
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-between">
      <div className="flex gap-2">
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </div>
      <GridToolbarQuickFilter debounceMs={500} />
    </GridToolbarContainer>
  );
};

// haven't done anything wiht this yet.
const DataGridContainerV2 = ({ columns, rows, pageSizeOptions = [5, 10, 20], localeText }) => {
  // Memoize columns to ensure they remain stable
  const memoizedColumns = useMemo(() => columns, [columns]);
  // Memoize pageSizeOptions to prevent recreating the array
  const memoizedPageSizeOptions = useMemo(() => pageSizeOptions, []);

  return (
    <div className='w-full h-[calc(100vh-20vh)]'> {/* Adjust 200px based on your layout */}
      <DataGridPro
        columns={memoizedColumns}
        rows={rows}
        pageSizeOptions={memoizedPageSizeOptions}
        localeText={localeText || { noRowsLabel: 'Run Analysis to see results' }}
        disableColumnMenu
        disableSelectionOnClick
        density="compact"
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

// Memoize the entire component to prevent unnecessary renders
export default memo(DataGridContainerV2);

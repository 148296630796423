import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import {useLocalDataStore, usePersistedDataStore} from '../store.js';
import _ from 'lodash';
import Button from '@mui/material/Button';
import CompSearch from './CompSearch.js';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useUser } from '../UserContext';
import BackToAnalysisButton from './BackToAnalysisButton';
import Typography from '@mui/material/Typography';

function CaseAnalysisNavigationTopNav({municipalityString}){
  // setloading can be deleted once your save comps is fixed.
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const compString = queryParams.get('comp') || null;
    const setTimeAdjustmentSettings = usePersistedDataStore((state) => state.setTimeAdjustmentSettings);
    const getReportType = usePersistedDataStore((state) => state.reportType);
    const nonCaseReview = getReportType === 'marketing';
    const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
    // if you need to use this here it is.
    // const comp = compString ? parseInt(compString-1) : null;

    // This means you don't update on the state update, only on the review page load.
    const memoizedNegotiationCases = useMemo(() => getNegotiationObj.cases, [getNegotiationObj.cases]);
    // console.log('reloading')

    const handleSelectSubject = useCallback(({suggestion, index}) => {
      console.log('selected subject')
      console.log(suggestion) // full address string
      console.log(index) // index of the selected subject
      const queryParams = new URLSearchParams(location.search);
      console.log('selected subject')
      console.log(suggestion) // full address string
      setReviewPage(false)
      queryParams.set('comp', index + 1);
      // navigate to the new url
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }, [memoizedNegotiationCases, location.search]);

    // Function to navigate back from the individual case container page to analysis page.
    const handleBackToAnalysis = useCallback(() => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('comp');
      setTimeAdjustmentSettings(null);
      setIsFetching(false);
      // navigate to the new url with preserved parameters
      navigate(`/analysis?${queryParams.toString()}`);
    }, [location.search, navigate]);

    return(
      <div className="sticky top-0 z-10">
        <div className={`relative testing inline overflow-clip w-full`}>
          <div id='sticky' className={`my-0.5 relative`}>
            <div className="absolute left-0">
              {!nonCaseReview && (
                <BackToAnalysisButton />
              )}
            </div>

            <Typography variant="h5" className="w-full text-center" gutterBottom my={2}>
              {municipalityString} Summary
            </Typography>

            {getReportType === 'standard' || getReportType === 'assessment' && (
              <div className="absolute right-0 top-0">
                <CompSearch
                  placeholderText='Subject Search'
                  compSelectCallback={handleSelectSubject}
                  searchData={memoizedNegotiationCases}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
}export default CaseAnalysisNavigationTopNav

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridToolbar,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import {
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Typography,
  IconButton,
  Stack,
  Tooltip,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import RemoveIcon from '@mui/icons-material/Remove';
import { userObject } from '../UserContext';

const COUNTY_CODES = {
  C: 'Columbia',
  D: 'Dutchess',
  G: 'Greene',
  L: 'Delaware',
  N: 'Nassau',
  O: 'Orange',
  P: 'Putnam',
  R: 'Rockland',
  S: 'Suffolk',
  U: 'Ulster',
  V: 'Sullivan',
  W: 'Westchester',
};

const MUNICIPAL_CODES = {
  'C': {  // Columbia
    'C06': 'Hudson',
    'C20': 'Ancram',
    'C22': 'Austerlitz',
    'C24': 'Canaan',
    'C26': 'Chatham',
    'C28': 'Claverack',
    'C30': 'Clermont',
    'C32': 'Copake',
    'C34': 'Gallatin',
    'C36': 'Germantown',
    'C38': 'Ghent',
    'C40': 'Greenport',
    'C42': 'Hillsdale',
    'C44': 'Kinderhook',
    'C46': 'Livingston',
    'C48': 'New Lebanon',
    'C50': 'Stockport',
    'C52': 'Stuyvesant',
    'C54': 'Taghkanic'
  },
  'D': {  // Dutchess
    'D01': 'Amenia',
    'D02': 'Beacon',
    'D13': 'Poughkeepsie City',
    'D20': 'Amenia',
    'D22': 'Beekman',
    'D24': 'Clinton',
    'D26': 'Dover',
    'D28': 'East Fishkill',
    'D30': 'Fishkill',
    'D32': 'Hyde Park',
    'D34': 'LaGrange',
    'D36': 'Milan',
    'D38': 'North East',
    'D40': 'Pawling',
    'D42': 'Pine Plains',
    'D44': 'Pleasant Valley',
    'D46': 'Poughkeepsie',
    'D48': 'Red Hook',
    'D50': 'Rhinebeck',
    'D52': 'Stanford',
    'D54': 'Union Vale',
    'D54': 'Wappinger',
    'D56': 'Washington'
  },
  'G': {  // Greene
    'G20': 'Ashland',
    'G22': 'Athens',
    'G24': 'Cairo',
    'G26': 'Catskill',
    'G28': 'Coxsackie',
    'G30': 'Durham',
    'G32': 'Greenville',
    'G34': 'Halcott',
    'G36': 'Hunter',
    'G38': 'Jewett',
    'G40': 'Lexington',
    'G42': 'New Baltimore',
    'G44': 'Prattsville',
    'G46': 'Windham'
  },
  'L': {  // Delaware
    'L20': 'Andes',
    'L22': 'Bovina',
    'L24': 'Colchester',
    'L26': 'Davenport',
    'L28': 'Delhi',
    'L30': 'Deposit',
    'L32': 'Franklin',
    'L34': 'Hamden',
    'L36': 'Hancock',
    'L38': 'Harpersfield',
    'L40': 'Kortright',
    'L42': 'Masonville',
    'L44': 'Meredith',
    'L46': 'Middletown',
    'L48': 'Roxbury',
    'L50': 'Sidney',
    'L52': 'Stamford',
    'L54': 'Tompkins',
    'L56': 'Walton'
  },
  'N': {  // Nassau
    'N05': 'Glen Cove City',
    'N06': 'Glen Cove',
    'N09': 'Long Beach City',
    'N10': 'Long Beach',
    'N20': 'Hempstead',
    'N22': 'North Hempstead',
    'N24': 'Oyster Bay'
  },
  'O': {  // Orange
    'O09': 'Middletown',
    'O11': 'Newburgh (City)',
    'O13': 'Port Jervis',
    'O20': 'Blooming Grove',
    'O22': 'Chester',
    'O24': 'Cornwall',
    'O26': 'Crawford',
    'O28': 'Deerpark',
    'O30': 'Goshen',
    'O32': 'Greenville',
    'O34': 'Hamptonburgh',
    'O36': 'Highlands',
    'O38': 'Minisink',
    'O40': 'Monroe',
    'O42': 'Montgomery',
    'O44': 'Mount Hope',
    'O46': 'Newburgh',
    'O48': 'New Windsor',
    'O50': 'Tuxedo',
    'O52': 'Wallkill',
    'O54': 'Warwick',
    'O56': 'Wawayanda',
    'O58': 'Woodbury'
  },
  'S': {  // Suffolk
    'S01': 'Babylon',
    'S02': 'Brookhaven',
    'S03': 'East Hampton',
    'S04': 'Huntington',
    'S05': 'Islip',
    'S06': 'Riverhead',
    'S07': 'Shelter Island',
    'S08': 'Smithtown',
    'S09': 'Southampton',
    'S10': 'Southold'
  },
  'U': {  // Ulster
    "U08": "Kingston City",
    'U20': 'Denning',
    'U22': 'Esopus',
    'U24': 'Gardiner',
    'U26': 'Hardenburgh',
    'U28': 'Hurley',
    'U30': 'Kingston',
    'U32': 'Lloyd',
    'U34': 'Marbletown',
    'U36': 'Marlborough',
    'U38': 'New Paltz',
    'U40': 'Olive',
    'U42': 'Plattekill',
    'U44': 'Rochester',
    'U46': 'Rosendale',
    'U48': 'Saugerties',
    'U50': 'Shandaken',
    'U52': 'Shawangunk',
    'U54': 'Ulster',
    'U56': 'Wawarsing',
    'U58': 'Woodstock'
  },
  'V': {  // Sullivan
    'V20': 'Bethel',
    'V22': 'Callicoon',
    'V24': 'Cochecton',
    'V26': 'Delaware',
    'V28': 'Fallsburg',
    'V30': 'Forestburgh',
    'V32': 'Fremont',
    'V34': 'Highland',
    'V36': 'Liberty',
    'V38': 'Lumberland',
    'V40': 'Mamakating',
    'V42': 'Neversink',
    'V44': 'Rockland',
    'V46': 'Thompson',
    'V48': 'Tusten'
  },
  'W': {  // Westchester
    'W08': 'Mt Vernon',
    'W10': 'New Rochelle',
    'W12': 'Peekskill',
    'W14': 'Rye City',
    'W17': 'White Plains',
    'W18': 'Yonkers',
    'W20': 'Bedford',
    'W22': 'Cortlandt',
    'W24': 'Eastchester',
    'W26': 'Greenburgh',
    'W28': 'Harrison',
    'W30': 'Lewisboro',
    'W32': 'Mamaroneck',
    'W34': 'Mount Pleasant',
    'W36': 'New Castle',
    'W38': 'North Castle',
    'W40': 'North Salem',
    'W42': 'Ossining',
    'W44': 'Pelham',
    'W46': 'Pound Ridge',
    'W48': 'Rye',
    'W50': 'Scarsdale',
    'W52': 'Somers',
    'W54': 'Yorktown',
    'W56': 'Mt Kisco'
  },
  'P': {  // Putnam
    'P20': 'Carmel',
    'P22': 'Kent',
    'P24': 'Patterson',
    'P26': 'Philipstown',
    'P28': 'Putnam Valley',
    'P30': 'Southeast'
  },
  'R': {  // Rockland
    'R20': 'Clarkstown',
    'R22': 'Haverstraw',
    'R24': 'Orangetown',
    'R26': 'Ramapo',
    'R28': 'Stony Point'
  }
};

const COLUMN_TYPES = {
  TEXT: 'text',
  INTEGER: 'integer',
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage'
};

const COMMON_COLUMN_CONFIGS = {
  currency: {
    type: COLUMN_TYPES.CURRENCY,
    valueFormatter: (value) => {
      return value?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }) || '-';
    },
    cellClassName: (params) =>
      params.value === 0 || params.value == null ? 'zero-value' : ''
  },
  percentage: {
    type: COLUMN_TYPES.PERCENTAGE,
    valueFormatter: (value) => {
      if (value == null || isNaN(value) || value === 0) {
        return '0%';
      }
      return `${(value * 100).toFixed(1)}%`;
    },
    cellClassName: (params) =>
      (params.value == null || isNaN(params.value) || params.value === 0)
        ? 'zero-value'
        : ''
  },
  integer: {
    type: COLUMN_TYPES.INTEGER,
    valueFormatter: (value) => value?.toLocaleString() || '0',
    cellClassName: (params) =>
      params.value == null || params.value === 0 ? 'zero-value' : ''
  }
};

function attachYoYDataToRows(currentYearRows, priorYearRows, fields) {
  const priorMap = new Map(priorYearRows.map(r => [r.id, r]));
  currentYearRows.forEach(curRow => {
    const priorRow = priorMap.get(curRow.id);
    if (!priorRow) return;

    fields.forEach(field => {
      const curVal = Number(curRow[field] || 0);
      const priorVal = Number(priorRow[field] || 0);
      curRow[field + 'YoY'] = curVal - priorVal;
    });
  });
}

function renderWithYoY(params, field, yoyField, useTooltip = false) {
  const value = params.value;
  const yoyChange = params.row[yoyField] ?? null;
  const isNumber = typeof value === 'number' && !isNaN(value);
  let formattedValue = value;

  // Define "bad" stats where decreases are good
  const badStats = [
    'scarStipNC',
    'scarDenied',
    'barDenied',
    'scarWithdrawn',
    'barWithdrawn'
  ];
  const isNegativeGood = badStats.includes(field);

  if (isNumber && (field.includes('Rate') || field.includes('Reduction'))) {
    formattedValue = (value * 100).toFixed(1) + '%';
  } else if (
    isNumber &&
    (field === 'revenue' || field === 'dollarsPerFile' || field === 'totalSavings')
  ) {
    formattedValue = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  } else if (isNumber) {
    formattedValue = value.toLocaleString();
  }

  const resolvedCount = useTooltip ? (params.row.portfolioValidCases || 0) : null;
  const totalCount = useTooltip
    ? (params.row[`${params.row.caseType?.toLowerCase() || 'scar'}Total`] || 0)
    : null;
  const isFullyResolved = useTooltip ? (resolvedCount === totalCount) : true;
  const isNassau =
    (params.row.path?.[0] === 'Nassau') || (params.row.ParcelID?.startsWith('N'));

  const getTooltipContent = () => {
    const lines = [];
    if (useTooltip && !isFullyResolved) {
      lines.push(`Based on ${resolvedCount} of ${totalCount} cases`);
    }
    if (yoyChange != null && yoyChange !== 0) {
      let yoyDisplay;
      if (
        field === 'revenue' ||
        field === 'dollarsPerFile' ||
        field === 'totalSavings'
      ) {
        yoyDisplay = yoyChange.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        });
      } else if (field.includes('Rate') || field.includes('Reduction')) {
        yoyDisplay = `${(yoyChange * 100).toFixed(1)}%`;
      } else {
        yoyDisplay = yoyChange.toLocaleString();
      }
      lines.push(`${yoyChange > 0 ? '+' : ''}${yoyDisplay}`);
    }
    return lines.length > 0 ? (
      <div style={{ whiteSpace: 'pre-line' }}>
        {lines.join('\n')}
      </div>
    ) : null;
  };

  const yoyContent = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, height: '100%' }}>
      <span>
        {formattedValue}
        {((useTooltip && !isFullyResolved) || (isNassau && field === 'revenue')) && (
          <span style={{ color: 'orange' }}>*</span>
        )}
      </span>
      {yoyChange == null ? (
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.25,
            fontSize: '0.75rem',
            color: 'text.disabled'
          }}
        >
          — 
        </Box>
      ) : yoyChange === 0 ? (
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.25,
            fontSize: '0.75rem',
            color: 'text.disabled'
          }}
        >
          <RemoveIcon sx={{ fontSize: '0.875rem' }} />
        </Box>
      ) : (
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.25,
            fontSize: '1.125rem',
            color: isNegativeGood 
              ? (yoyChange < 0 ? 'success.main' : 'error.main')
              : (yoyChange > 0 ? 'success.main' : 'error.main'),
            cursor: 'pointer'
          }}
        >
          {yoyChange > 0 ? (
            <TrendingUpIcon sx={{ fontSize: '1.125rem' }} />
          ) : (
            <TrendingDownIcon sx={{ fontSize: '1.125rem' }} />
          )}
        </Box>
      )}
    </Box>
  );

  const tooltipContent = getTooltipContent();
  return tooltipContent ? (
    <Tooltip title={tooltipContent} arrow disableInteractive>
      {yoyContent}
    </Tooltip>
  ) : yoyContent;
}

function renderWithYoYIfEnabled(showYoY, params, field, yoyField, useTooltip = false) {
  if (!showYoY) {
    const resolvedCount = useTooltip ? (params.row.portfolioValidCases || 0) : null;
    const totalCount = useTooltip
      ? (params.row[`${params.row.caseType?.toLowerCase() || 'scar'}Total`] || 0)
      : null;
    const isFullyResolved = useTooltip ? (resolvedCount === totalCount) : true;
    const isNassau = params.row.path?.[0] === 'Nassau' || params.row.ParcelID?.startsWith('N');

    const content = (
      <span>
        {params.value}
        {((useTooltip && !isFullyResolved) || (isNassau && field === 'revenue')) && (
          <span style={{ color: 'orange' }}>*</span>
        )}
      </span>
    );

    if (useTooltip && !isFullyResolved) {
      return (
        <Tooltip title={`Based on ${resolvedCount} of ${totalCount} cases`} arrow disableInteractive>
          {content}
        </Tooltip>
      );
    }
    return content;
  }
  return renderWithYoY(params, field, yoyField, useTooltip);
}

function makeYoYRenderCell(showYoY, field, yoyField, configType = 'integer', useTooltip = false) {
  const config = COMMON_COLUMN_CONFIGS[configType];
  return (params) => {
    const formattedValue = config.valueFormatter(params.value);
    return renderWithYoYIfEnabled(
      showYoY,
      { ...params, value: formattedValue },
      field,
      yoyField,
      useTooltip
    );
  };
}

// Column definitions
const COLUMN_DEFINITIONS = {
  basic: {
    parcelId: {
      field: 'ParcelID',
      headerName: 'Parcel ID',
      flex: 1,
      minWidth: 120,
    },
    address: {
      field: 'Address',
      headerName: 'Address',
      flex: 1,
      minWidth: 200,
      type: COLUMN_TYPES.TEXT,
    },
    priorAssessment: {
      field: 'PriorYearAssessment',
      headerName: 'Prior Assessment',
      flex: 1,
      minWidth: 130,
      ...COMMON_COLUMN_CONFIGS.currency
    },
    taxes: {
      field: 'Taxes',
      headerName: 'Taxes',
      flex: 1,
      minWidth: 120,
      ...COMMON_COLUMN_CONFIGS.currency
    },
    negotiator: {
      field: 'Negotiator',
      headerName: 'Negotiator',
      flex: 1,
      minWidth: 120,
      type: COLUMN_TYPES.TEXT,
      valueFormatter: (value) => {
        const negotiatorId = value;
        const matchingUser = Object.values(userObject).find(
          user => user.userId === Number(negotiatorId)
        );
        return matchingUser
          ? matchingUser.userName
          : (negotiatorId || 'Unassigned');
      },
    }
  },
  metrics: {
    percentReduction: {
      field: 'PercentReduction',
      headerName: 'Reduction %',
      flex: 1,
      minWidth: 120,
      ...COMMON_COLUMN_CONFIGS.percentage
    },
    totalSavings: {
      field: 'totalSavings',
      headerName: 'Total Savings',
      flex: 1,
      minWidth: 100,
      ...COMMON_COLUMN_CONFIGS.currency
    },
    revenue: {
      field: 'revenue',
      headerName: 'Revenue',
      flex: 1,
      minWidth: 100,
      type: COLUMN_TYPES.CURRENCY
    },
    getDollarsPerFile: (caseType) => ({
      field: 'dollarsPerFile',
      headerName: '$ / File',
      type: COLUMN_TYPES.CURRENCY,
      flex: 1,
      minWidth: 100,
    })
  },
  scar: {
    hearingOfficer: {
      field: 'SCARHearingOfficer',
      headerName: 'Hearing Officer',
      flex: 1,
      minWidth: 130,
    },
    determinationValue: {
      field: 'SCARDeterminationValue',
      headerName: 'SCAR Value',
      flex: 1,
      minWidth: 130,
      ...COMMON_COLUMN_CONFIGS.currency
    },
    action: {
      field: 'SCARDeterminationAction',
      headerName: 'SCAR Action',
      flex: 1,
      minWidth: 110,
    }
  },
  bar: {
    determinationValue: {
      field: 'BARDeterminationValue',
      headerName: 'BAR Value',
      flex: 1,
      minWidth: 130,
      ...COMMON_COLUMN_CONFIGS.currency
    },
    action: {
      field: 'BARDeterminationAction',
      headerName: 'BAR Action',
      flex: 1,
      minWidth: 110,
    }
  },
  grouping: {
    treeDataGroup: (groupBy) => ({
      field:
        groupBy === 'hearingOfficer'
          ? 'SCARHearingOfficer'
          : groupBy === 'negotiator'
          ? 'Negotiator'
          : '__tree_data_group__',
      headerName:
        groupBy === 'county'
          ? 'County/Municipality'
          : groupBy === 'hearingOfficer'
          ? 'Hearing Officer'
          : 'Negotiator',
      minWidth: 150
    })
  },
  stats: {
    totalCases: (caseType, showYoY) => ({
      field: `${caseType.toLowerCase()}Total`,
      headerName: `${caseType} Cases`,
      flex: 1,
      minWidth: 80,
      ...COMMON_COLUMN_CONFIGS.integer,
      renderCell: makeYoYRenderCell(
        showYoY,
        `${caseType.toLowerCase()}Total`,
        `${caseType.toLowerCase()}TotalYoY`,
        'integer'
      )
    }),
    portfolioValidCases: {
      field: 'portfolioValidCases',
      headerName: 'Resolved',
      flex: 1,
      minWidth: 105,
      renderCell: (params) => {
        const resolvedCount = params.value || 0;
        const percentage = params.row.resolvedPercentage || 0;
        const percentageClassName = percentage === 0 ? 'zero-value' : '';

        return (
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
            <span className={resolvedCount === 0 ? 'zero-value' : ''}>
              {resolvedCount.toLocaleString()}
            </span>
            <Typography
              variant="caption"
              className={percentageClassName}
              sx={{ opacity: percentageClassName ? 0.38 : 0.8 }}
            >
              ({Math.floor(percentage * 100)}%)
            </Typography>
          </Box>
        );
      }
    },
    caseStatus: (caseType, status, showYoY) => ({
      field: `${caseType.toLowerCase()}${status}`,
      headerName: status,
      flex: 1,
      minWidth: 65,
      ...COMMON_COLUMN_CONFIGS.integer,
      renderCell: makeYoYRenderCell(
        showYoY,
        `${caseType.toLowerCase()}${status}`,
        `${caseType.toLowerCase()}${status}YoY`,
        'integer'
      )
    }),
    scarSpecific: {
      stipNC: (showYoY) => ({
        field: 'scarStipNC',
        headerName: 'Stip NC',
        flex: 1,
        minWidth: 80,
        ...COMMON_COLUMN_CONFIGS.integer,
        renderCell: makeYoYRenderCell(
          showYoY,
          'scarStipNC',
          'scarStipNCYoY',
          'integer'
        )
      }),
      notInvoicable: {
        field: 'scarNotInvoicable',
        headerName: 'Not Invoicable',
        flex: 1,
        minWidth: 70,
        ...COMMON_COLUMN_CONFIGS.integer
      },
      awaitingDecision: {
        field: 'scarAwaitingDecision',
        headerName: 'Awaiting',
        flex: 1,
        minWidth: 90,
        ...COMMON_COLUMN_CONFIGS.integer
      },
      adminHold: {
        field: 'scarAdminHold',
        headerName: 'Admin Hold',
        flex: 1,
        minWidth: 95,
        ...COMMON_COLUMN_CONFIGS.integer
      }
    },
    stipulated: (caseType, showYoY) => ({
      field: `${caseType.toLowerCase()}Settled`,
      headerName: 'Stip',
      flex: 1,
      minWidth: 70,
      ...COMMON_COLUMN_CONFIGS.integer,
      renderCell: makeYoYRenderCell(
        showYoY,
        `${caseType.toLowerCase()}Settled`,
        `${caseType.toLowerCase()}SettledYoY`,
        'integer'
      )
    }),
    decisions: (showYoY) => ({
      field: 'scarDecisions',
      headerName: 'Decisions',
      flex: 1,
      minWidth: 80,
      ...COMMON_COLUMN_CONFIGS.integer,
      renderCell: makeYoYRenderCell(
        showYoY,
        'scarDecisions',
        'scarDecisionsYoY',
        'integer'
      )
    }),
  },
  rates: {
    averageReduction: (showYoY) => ({
      field: 'averageReduction',
      headerName: 'Avg Reduction',
      flex: 1,
      minWidth: 100,
      ...COMMON_COLUMN_CONFIGS.percentage,
      renderCell: makeYoYRenderCell(
        showYoY,
        'averageReduction',
        'averageReductionYoY',
        'percentage'
      )
    }),
    portfolioReduction: (showYoY) => ({
      field: 'portfolioReduction',
      headerName: 'Portfolio Reduction',
      flex: 1,
      minWidth: 100,
      ...COMMON_COLUMN_CONFIGS.percentage,
      renderCell: makeYoYRenderCell(
        showYoY,
        'portfolioReduction',
        'portfolioReductionYoY',
        'percentage',
        true
      )
    }),
    settlementRate: (caseType) => ({
      field: 'settlementRate',
      headerName: 'Settlement %',
      flex: 1,
      minWidth: 95,
      ...COMMON_COLUMN_CONFIGS.percentage
    }),
    winRate: (caseType, showYoY) => ({
      field: 'winRate',
      headerName: 'Win Rate',
      flex: 1,
      minWidth: 80,
      ...COMMON_COLUMN_CONFIGS.percentage,
      renderCell: makeYoYRenderCell(
        showYoY,
        'winRate',
        'winRateYoY',
        'percentage',
        true
      )
    }),
    decisionWinRate: (showYoY) => ({
      field: 'decisionWinRate',
      headerName: 'Decision Win',
      flex: 1,
      minWidth: 105,
      ...COMMON_COLUMN_CONFIGS.percentage,
      renderCell: makeYoYRenderCell(
        showYoY,
        'decisionWinRate',
        'decisionWinRateYoY',
        'percentage'
      )
    })
  }
};

function safeNumber(value) {
  const num = Number(value);
  return isNaN(num) ? 0 : num;
}

function getPriorAssessment(item) {
  const priorValue = safeNumber(item.PriorYearAssessment);
  if (priorValue === 0) {
    if (
      (item.BARDeterminationAction === 'BD' || item.SCARDeterminationAction === 'SD') &&
      item.SCARDeterminationAction !== 'D'
    ) {
      return item.BARDeterminationAction === 'BD'
        ? safeNumber(item.BARDeterminationValue)
        : safeNumber(item.SCARDeterminationValue);
    }
  }
  return priorValue;
}

function calculateSavings(priorValue, determinationValue, taxes) {
  const rawSavings =
    priorValue > 0 && determinationValue > 0
      ? (priorValue - determinationValue) * (safeNumber(taxes) / priorValue)
      : 0;
  return Math.max(0, rawSavings);
}

function calculateCaseCounts(items, caseType) {
  const prefix = caseType.toLowerCase();
  return items.reduce((acc, item) => {
    const action = prefix === 'scar'
      ? item.SCARDeterminationAction
      : item.BARDeterminationAction;
    const noHearingOfficer =
      item.SCARHearingOfficer === 'Unassigned' || item.SCARHearingOfficer == null;
    const isWithdrawn = action === 'W' || action === 'BW';

    acc[`${prefix}Total`] = (acc[`${prefix}Total`] || 0) + 1;

    if (prefix === 'scar') {
      if (!noHearingOfficer) {
        acc.scarDecisions = (acc.scarDecisions || 0) + 1;
        if (action === 'S' || action === 'ST') {
          acc.scarWon = (acc.scarWon || 0) + 1;
        } else if (action === 'SD') {
          acc.scarDenied = (acc.scarDenied || 0) + 1;
        }
      } else {
        if (action === 'S' || action === 'ST') {
          acc.scarSettled = (acc.scarSettled || 0) + 1;
        } else if (action === 'SD') {
          acc.scarStipNC = (acc.scarStipNC || 0) + 1;
        }
      }
      if (action === 'NM') {
        acc.scarNotInvoicable = (acc.scarNotInvoicable || 0) + 1;
      } else if (action === 'D') {
        acc.scarAwaitingDecision = (acc.scarAwaitingDecision || 0) + 1;
      } else if (action === 'AH') {
        acc.scarAdminHold = (acc.scarAdminHold || 0) + 1;
      } else if (isWithdrawn) {
        acc.scarWithdrawn = (acc.scarWithdrawn || 0) + 1;
      }
    } else {
      // BAR
      if (action === 'BS') {
        acc.barWon = (acc.barWon || 0) + 1;
      } else if (action === 'BT') {
        acc.barSettled = (acc.barSettled || 0) + 1;
      } else if (action === 'BD') {
        acc.barDenied = (acc.barDenied || 0) + 1;
      } else if (action === 'BM') {
        acc.barNotInvoicable = (acc.barNotInvoicable || 0) + 1;
      } else if (isWithdrawn) {
        acc.barWithdrawn = (acc.barWithdrawn || 0) + 1;
      }
    }

    return acc;
  }, {});
}

function calculateReductions(items, caseType) {
  const prefix = caseType.toLowerCase();
  const caseCounts = calculateCaseCounts(items, caseType);

  const reductionStats = items.reduce(
    (acc, item) => {
      const priorValue = getPriorAssessment(item);
      const determinationValue = prefix === 'bar'
        ? safeNumber(item.BARDeterminationValue)
        : safeNumber(item.SCARDeterminationValue);

      const action = prefix === 'bar'
        ? item.BARDeterminationAction
        : item.SCARDeterminationAction;
      const isWithdrawn = action === 'W' || action === 'BW';
      const noHearingOfficer =
        item.SCARHearingOfficer === 'Unassigned' || item.SCARHearingOfficer == null;

      if (priorValue > 0 && determinationValue > 0 && !isWithdrawn) {
        const isSuccess =
          (prefix === 'scar' &&
            ((!noHearingOfficer && (action === 'S' || action === 'ST')) ||
             (noHearingOfficer && action === 'S') ||
             action === 'NM')) ||
          (prefix === 'bar' && (action === 'BS' || action === 'BT'));

        if (isSuccess) {
          const reduction = (priorValue - determinationValue) / priorValue;
          acc.reductionSum += reduction;
          acc.reductionCount += 1;
        }
        acc.portfolioPriorTotal += priorValue;
        acc.portfolioDeterminationTotal += determinationValue;
      }

      return acc;
    },
    {
      reductionSum: 0,
      reductionCount: 0,
      portfolioPriorTotal: 0,
      portfolioDeterminationTotal: 0
    }
  );

  return {
    ...caseCounts,
    ...reductionStats
  };
}

function calculateGroupStats(items, caseType) {
  const prefix = caseType.toLowerCase();
  const caseCounts = calculateCaseCounts(items, caseType);
  const reductions = calculateReductions(items, caseType);

  const wonCases =
    (caseCounts[`${prefix}Won`] || 0) +
    (caseCounts[`${prefix}Settled`] || 0) +
    (caseCounts[`${prefix}NotInvoicable`] || 0);

  const totalResolvedCases =
    wonCases +
    (caseCounts[`${prefix}Denied`] || 0) +
    (caseCounts[`${prefix}Withdrawn`] || 0) +
    (prefix === 'scar' ? caseCounts.scarStipNC || 0 : 0);

  const winRate =
    totalResolvedCases > 0 ? wonCases / totalResolvedCases : 0;

  const totalSettlements =
    (caseCounts[`${prefix}Settled`] || 0) +
    (prefix === 'scar' ? (caseCounts.scarStipNC || 0) : 0) +
    (caseCounts[`${prefix}Withdrawn`] || 0);

  const totalCases = caseCounts[`${prefix}Total`] || 0;
  const settlementRate =
    totalCases > 0 ? totalSettlements / totalCases : 0;

  let decisionWinRate = null;
  if (prefix === 'scar') {
    const scarDecisions = caseCounts.scarDecisions || 0;
    const scarNotInvoicable = caseCounts.scarNotInvoicable || 0;
    if (scarDecisions + scarNotInvoicable > 0) {
      decisionWinRate =
        ((caseCounts.scarWon || 0) + scarNotInvoicable) /
        (scarDecisions + scarNotInvoicable);
    }
  }

  const resolvedCases = items.filter(item => {
    const priorValue = getPriorAssessment(item);
    const determinationValue = prefix === 'bar'
      ? safeNumber(item.BARDeterminationValue)
      : safeNumber(item.SCARDeterminationValue);
    const action = prefix === 'bar'
      ? item.BARDeterminationAction
      : item.SCARDeterminationAction;
    
    // Include cases with valid prior and determination values
    // For BAR, check if there's a valid action (BS, BT, BD, BW)
    return priorValue > 0 && determinationValue > 0 && 
           (prefix === 'scar' || (action && action.startsWith('B')));
  }).length;

  const resolvedPercentage = totalCases > 0 ? resolvedCases / totalCases : 0;

  const portfolioStats = items.reduce(
    (acc, item) => {
      const priorValue = getPriorAssessment(item);
      const determinationValue =
        prefix === 'bar'
          ? safeNumber(item.BARDeterminationValue)
          : safeNumber(item.SCARDeterminationValue);
      if (priorValue > 0 && determinationValue > 0) {
        acc.portfolioPriorTotal += priorValue;
        acc.portfolioDeterminationTotal += determinationValue;
      }
      return acc;
    },
    { portfolioPriorTotal: 0, portfolioDeterminationTotal: 0 }
  );

  const portfolioReduction =
    portfolioStats.portfolioPriorTotal > 0
      ? (portfolioStats.portfolioPriorTotal -
         portfolioStats.portfolioDeterminationTotal) /
        portfolioStats.portfolioPriorTotal
      : 0;

  const averageReduction =
    reductions.reductionCount > 0
      ? reductions.reductionSum / reductions.reductionCount
      : 0;

  const revenue = items.reduce((acc, item) => {
    const priorValue = getPriorAssessment(item);
    const determinationValue =
      prefix === 'bar'
        ? safeNumber(item.BARDeterminationValue)
        : safeNumber(item.SCARDeterminationValue);
    const savings = calculateSavings(priorValue, determinationValue, item.Taxes);
    const isNassau = item.ParcelID?.startsWith('N');
    return acc + savings * (isNassau ? 0.4 : 0.5);
  }, 0);

  const totalSavings = items.reduce((acc, item) => {
    const priorValue = getPriorAssessment(item);
    const determinationValue =
      prefix === 'bar'
        ? safeNumber(item.BARDeterminationValue)
        : safeNumber(item.SCARDeterminationValue);
    return acc + calculateSavings(priorValue, determinationValue, item.Taxes);
  }, 0);

  const totalTaxes = items.reduce(
    (acc, item) => acc + safeNumber(item.Taxes),
    0
  );

  return {
    ...caseCounts,
    ...reductions,
    winRate,
    settlementRate,
    decisionWinRate,
    resolvedPercentage,
    revenue,
    totalSavings,
    dollarsPerFile: resolvedCases > 0 ? revenue / resolvedCases : 0,
    averageReduction,
    portfolioReduction,
    portfolioValidCases: resolvedCases,
    totalTaxes
  };
}

function SettlementTable({
  data,
  multiYearData,
  selectedYear,
  onBack,
  taxYear
}) {
  const treeApiRef = useGridApiRef();
  const gridApiRef = useGridApiRef();

  const [groupBy, setGroupBy] = useState('county');
  const [caseType, setCaseType] = useState('SCAR');
  const [selectedMuniCode, setSelectedMuniCode] = useState(null);
  const [selectedNegotiator, setSelectedNegotiator] = useState(null);
  const [showYoY, setShowYoY] = useState(true);

  // NEW: We'll manage column visibility. SCAR's "Awaiting"/"AdminHold" hidden by default:
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    scarAwaitingDecision: false,
    scarAdminHold: false
  });

  // 1) Update the click handlers to properly handle drill-downs:
  const handleTreeCellClick = useCallback(
    (params) => {
      if (params.id === 'totals') return;
      if (params.field !== '__tree_data_group__') return;

      // Clear any existing selections first
      setSelectedNegotiator(null);

      // Handle clicks based on path length
      if (params.row.path?.length === 1) {
        const countyCode = params.row.id.split('-')[1].charAt(0);
        setSelectedMuniCode(countyCode);
        setGroupBy('municipality');
      } else if (params.row.path?.length === 2) {
        const muniCode = params.row.id.substring(0, 3);
        setSelectedMuniCode(muniCode);
        setGroupBy('municipality');
      }
    },
    []
  );

  const handleNonTreeCellClick = useCallback(
    (params) => {
      if (params.id === 'totals') return;
      if (
        params.field === 'SCARHearingOfficer' ||
        params.field === 'Negotiator' ||
        params.field === '__tree_data_group__'
      ) {
        // Clear any existing selections first
        setSelectedMuniCode(null);
        
        if (groupBy === 'hearingOfficer') {
          setSelectedNegotiator(params.row.SCARHearingOfficer);
          setGroupBy('municipality');
        } else if (groupBy === 'negotiator') {
          setSelectedNegotiator(params.row.Negotiator);
          setGroupBy('municipality');
        }
      }
    },
    [groupBy]
  );

  // 2) Update the event subscriptions:
  useEffect(() => {
    if (groupBy === 'county' && treeApiRef.current) {
      const unsubscribe = treeApiRef.current.subscribeEvent(
        'cellClick',
        handleTreeCellClick
      );
      return () => unsubscribe();
    }
  }, [groupBy, handleTreeCellClick]);

  useEffect(() => {
    if ((groupBy === 'hearingOfficer' || groupBy === 'negotiator') && gridApiRef.current) {
      const unsubscribe = gridApiRef.current.subscribeEvent(
        'cellClick',
        handleNonTreeCellClick
      );
      return () => unsubscribe();
    }
  }, [groupBy, handleNonTreeCellClick]);

  // Decide which year is active
  const [activeYear, setActiveYear] = useState(() => {
    if (selectedYear && multiYearData && multiYearData[selectedYear]) {
      return selectedYear.toString();
    }
    return Object.keys(multiYearData || {})[0] || '';
  });

  // Available year tabs
  const availableYears = useMemo(() => {
    return Object.keys(multiYearData || {}).sort((a, b) => a - b);
  }, [multiYearData]);

  const handleYearChange = (event, newYear) => {
    if (!newYear) return;
    setActiveYear(newYear);
    setSelectedMuniCode(null);
    setSelectedNegotiator(null);
  };

  // Filter data for the active year
  const filteredData = useMemo(() => {
    return multiYearData?.[activeYear] || [];
  }, [multiYearData, activeYear]);

  const effectiveShowYoY = useMemo(() => {
    // We'll disable YoY if activeYear == 2023
    return showYoY && activeYear !== '2023';
  }, [showYoY, activeYear]);

  const YOY_FIELDS = [
    'scarTotal',
    'barTotal',
    'scarDecisions',
    'scarSettled',
    'scarStipNC',
    'scarNotInvoicable',
    'scarAwaitingDecision',
    'scarAdminHold',
    'scarWithdrawn',
    'scarWon',
    'scarDenied',
    'barWon',
    'barSettled',
    'barDenied',
    'barNotInvoicable',
    'barWithdrawn',
    'settlementRate',
    'winRate',
    'decisionWinRate',
    'portfolioReduction',
    'averageReduction',
    'totalSavings',
    'revenue',
    'dollarsPerFile',
    'totalTaxes'
  ];

  // Build base rows
  const baseRows = useMemo(() => {
    const caseTypeFilteredData = filteredData.filter(item => {
      if (caseType === 'SCAR') return item.SCARFiled === 1;
      if (caseType === 'BAR') return item.BARFiled === 1;
      return true;
    });

    let rows = [];
    if (groupBy === 'municipality') {
      let filteredItems = caseTypeFilteredData;
      if (selectedMuniCode) {
        filteredItems = filteredItems.filter(
          (item) => item.ParcelID?.startsWith(selectedMuniCode)
        );
      } else if (selectedNegotiator) {
        filteredItems = filteredItems.filter(item => {
          if (groupBy === 'hearingOfficer' || item.SCARHearingOfficer === selectedNegotiator) {
            return item.SCARHearingOfficer === selectedNegotiator;
          }
          const negotiatorId = item.Negotiator;
          const matchingUser = Object.values(userObject).find(
            user => user.userId === Number(negotiatorId)
          );
          const negotiatorName = matchingUser
            ? matchingUser.userName
            : (negotiatorId || 'Unassigned');
          return negotiatorName === selectedNegotiator;
        });
      }
      rows = filteredItems.map((item, index) => ({
        id: index,
        ...item,
        ...calculateGroupStats([item], caseType)
      }));
    } else if (groupBy === 'hearingOfficer') {
      // Only items that have a hearing officer assigned
      const hoGroups = {};
      caseTypeFilteredData.forEach(item => {
        const officer = item.SCARHearingOfficer;
        if (officer && officer !== 'Unassigned') {
          if (!hoGroups[officer]) hoGroups[officer] = [];
          hoGroups[officer].push(item);
        }
      });
      rows = Object.entries(hoGroups).map(([officer, items]) => ({
        id: officer,
        SCARHearingOfficer: officer,
        ...calculateGroupStats(items, caseType)
      }));
    } else if (groupBy === 'negotiator') {
      const negotiatorGroups = {};
      caseTypeFilteredData.forEach(item => {
        const negotiatorId = item.Negotiator;
        const matchingUser = Object.values(userObject).find(
          user => user.userId === Number(negotiatorId)
        );
        const negotiatorName = matchingUser
          ? matchingUser.userName
          : (negotiatorId || 'Unassigned');

        if (!negotiatorGroups[negotiatorName]) {
          negotiatorGroups[negotiatorName] = [];
        }
        negotiatorGroups[negotiatorName].push(item);
      });
      rows = Object.entries(negotiatorGroups).map(([negName, items]) => ({
        id: negName,
        Negotiator: negName,
        ...calculateGroupStats(items, caseType)
      }));
    } else {
      // groupBy === 'county'
      const municipalityGroups = new Map();
      caseTypeFilteredData.forEach(item => {
        const muniCode = item.ParcelID?.substring(0, 3);
        if (!muniCode) return;
        if (!municipalityGroups.has(muniCode)) {
          municipalityGroups.set(muniCode, []);
        }
        municipalityGroups.get(muniCode).push(item);
      });

      const municipalityRows = Array.from(municipalityGroups.entries()).map(
        ([muniCode, items]) => {
          const countyCode = muniCode.charAt(0);
          const countyName = COUNTY_CODES[countyCode];
          const muniName =
            MUNICIPAL_CODES[countyCode]?.[muniCode] || muniCode;
          return {
            id: muniCode,
            path: [countyName, `${muniCode} - ${muniName}`],
            municipality: `${muniCode} - ${muniName}`,
            county: countyName,
            caseType,
            ...calculateGroupStats(items, caseType)
          };
        }
      );

      const countyRows = Object.values(COUNTY_CODES).map(countyName => {
        const countyItems = caseTypeFilteredData.filter(item => {
          const cc = item.ParcelID?.charAt(0);
          return COUNTY_CODES[cc] === countyName;
        });
        return {
          id: `county-${countyName}`,
          path: [countyName],
          county: countyName,
          caseType,
          ...calculateGroupStats(countyItems, caseType)
        };
      });

      rows = [...countyRows, ...municipalityRows];
    }

    return rows;
  }, [
    filteredData,
    groupBy,
    caseType,
    selectedMuniCode,
    selectedNegotiator
  ]);

  // Totals row (hearing officer excludes unassigned)
  const totalsRow = useMemo(() => {
    if (!filteredData?.length) return null;

    let relevantData = filteredData.filter(item => {
      if (caseType === 'SCAR') return item.SCARFiled === 1;
      if (caseType === 'BAR') return item.BARFiled === 1;
      return true;
    });

    // If hearingOfficer grouping, exclude unassigned
    if (groupBy === 'hearingOfficer' && caseType === 'SCAR') {
      relevantData = relevantData.filter(
        (item) =>
          item.SCARHearingOfficer && item.SCARHearingOfficer !== 'Unassigned'
      );
    }

    // Log for debugging
    console.log('Totals row data:', {
      caseType,
      relevantDataLength: relevantData.length,
      stats: calculateGroupStats(relevantData, caseType)
    });

    if (!relevantData.length) return null;

    const stats = calculateGroupStats(relevantData, caseType);
    return {
      id: 'totals',
      path: ['Totals'],
      county: 'Totals',
      municipality: 'Totals',
      SCARHearingOfficer: 'Totals',
      Negotiator: 'Totals',
      caseType,
      ...stats
    };
  }, [filteredData, caseType, groupBy]);

  // Combine them so we can attach YoY to all
  const allRowsWithTotals = useMemo(() => {
    let combined = [...baseRows];
    if (totalsRow) {
      combined.push(totalsRow);
    }

    const priorYear = String(parseInt(activeYear, 10) - 1);
    if (multiYearData?.[priorYear]) {
      const priorYearFull = multiYearData[priorYear].filter(item => {
        if (caseType === 'SCAR') return item.SCARFiled === 1;
        if (caseType === 'BAR') return item.BARFiled === 1;
        return true;
      });

      // If we have a totals row, build priorTotals
      let priorTotals = null;
      if (totalsRow) {
        let filteredPrior = priorYearFull;
        if (groupBy === 'hearingOfficer' && caseType === 'SCAR') {
          filteredPrior = filteredPrior.filter(
            it => it.SCARHearingOfficer && it.SCARHearingOfficer !== 'Unassigned'
          );
        }
        const stats = calculateGroupStats(filteredPrior, caseType);
        priorTotals = { id: 'totals', ...stats };
      }

      let priorGrouped = [];
      if (groupBy === 'municipality') {
        priorGrouped = priorYearFull.map((item, idx) => ({
          id: idx,
          ...item,
          ...calculateGroupStats([item], caseType)
        }));
      } else if (groupBy === 'hearingOfficer') {
        const hoGroups = {};
        priorYearFull.forEach(item => {
          if (item.SCARHearingOfficer && item.SCARHearingOfficer !== 'Unassigned') {
            if (!hoGroups[item.SCARHearingOfficer]) {
              hoGroups[item.SCARHearingOfficer] = [];
            }
            hoGroups[item.SCARHearingOfficer].push(item);
          }
        });
        priorGrouped = Object.entries(hoGroups).map(([o, arr]) => ({
          id: o,
          ...calculateGroupStats(arr, caseType)
        }));
      } else if (groupBy === 'negotiator') {
        const negGroups = {};
        priorYearFull.forEach(item => {
          const negotiatorId = item.Negotiator;
          const matchingUser = Object.values(userObject).find(
            u => u.userId === Number(negotiatorId)
          );
          const negotiatorName = matchingUser
            ? matchingUser.userName
            : (negotiatorId || 'Unassigned');
          if (!negGroups[negotiatorName]) negGroups[negotiatorName] = [];
          negGroups[negotiatorName].push(item);
        });
        priorGrouped = Object.entries(negGroups).map(([n, arr]) => ({
          id: n,
          ...calculateGroupStats(arr, caseType)
        }));
      } else {
        // groupBy === 'county'
        const muniMap = new Map();
        priorYearFull.forEach(item => {
          const code = item.ParcelID?.substring(0, 3);
          if (!code) return;
          if (!muniMap.has(code)) muniMap.set(code, []);
          muniMap.get(code).push(item);
        });

        const priorMuniRows = Array.from(muniMap.entries()).map(([c, items]) => ({
          id: c,
          ...calculateGroupStats(items, caseType)
        }));

        const priorCountyRows = Object.values(COUNTY_CODES).map(countyName => {
          const cItems = priorYearFull.filter(it => {
            const cc = it.ParcelID?.charAt(0);
            return COUNTY_CODES[cc] === countyName;
          });
          return {
            id: `county-${countyName}`,
            ...calculateGroupStats(cItems, caseType)
          };
        });

        priorGrouped = [...priorCountyRows, ...priorMuniRows];
      }

      if (priorTotals) {
        priorGrouped.push(priorTotals);
      }

      attachYoYDataToRows(combined, priorGrouped, YOY_FIELDS);
    }

    return combined;
  }, [
    baseRows,
    totalsRow,
    multiYearData,
    activeYear,
    caseType,
    groupBy
  ]);

  const renderedRows = allRowsWithTotals.filter(r => r.id !== 'totals');

  // ----------------------
  // Build columns
  // ----------------------
  const columns = useMemo(() => {
    if (groupBy === 'municipality') {
      return [
        COLUMN_DEFINITIONS.basic.parcelId,
        COLUMN_DEFINITIONS.basic.address,
        COLUMN_DEFINITIONS.basic.priorAssessment,
        caseType === 'BAR'
          ? COLUMN_DEFINITIONS.bar.determinationValue
          : COLUMN_DEFINITIONS.scar.determinationValue,
        caseType === 'BAR'
          ? COLUMN_DEFINITIONS.bar.action
          : COLUMN_DEFINITIONS.scar.action,
        ...(caseType === 'SCAR'
          ? [COLUMN_DEFINITIONS.scar.hearingOfficer]
          : []),
        COLUMN_DEFINITIONS.basic.negotiator,
        COLUMN_DEFINITIONS.basic.taxes,
        COLUMN_DEFINITIONS.metrics.percentReduction,
        COLUMN_DEFINITIONS.metrics.totalSavings,
        {
          ...COLUMN_DEFINITIONS.metrics.revenue,
          renderCell: (params) => {
            const isNassau =
              params.row.ParcelID?.startsWith('N') ||
              params.row.path?.[0] === 'Nassau';
            const val = params.value || 0;
            const formattedValue = val.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            });
            if (isNassau) {
              return (
                <Tooltip
                  title="This assumes a 40% fee retainer"
                  disableInteractive
                >
                  <span>
                    {renderWithYoYIfEnabled(
                      false,
                      { ...params, value: formattedValue },
                      'revenue',
                      'revenueYoY',
                      false
                    )}
                    <span style={{ color: 'orange' }}>*</span>
                  </span>
                </Tooltip>
              );
            }
            return renderWithYoYIfEnabled(
              false,
              { ...params, value: formattedValue },
              'revenue',
              'revenueYoY',
              false
            );
          }
        }
      ];
    }

    const taxesColumn = {
      field: 'totalTaxes',
      headerName: 'Taxes',
      ...COMMON_COLUMN_CONFIGS.currency,
      flex: 1,
      minWidth: 110
    };

    const baseColumns = [
      {
        ...COLUMN_DEFINITIONS.grouping.treeDataGroup(groupBy),
        pinned: true,
        cellClassName: (params) => {
          if (params.id === 'totals') return '';
          if (groupBy === 'county') {
            if (params.row.path?.length === 1) return 'clickable-county';
            if (params.row.path?.length === 2) return 'clickable-muni';
          }
          // Add clickable-cell class for hearing officer and negotiator
          if (groupBy === 'hearingOfficer' || groupBy === 'negotiator') {
            return 'clickable-cell';
          }
          return '';
        }
      },
      COLUMN_DEFINITIONS.stats.totalCases(caseType, effectiveShowYoY)
    ];

    if (groupBy === 'hearingOfficer' && caseType === 'SCAR') {
      baseColumns.push(
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Won', effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Denied', effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.scarSpecific.notInvoicable,
        COLUMN_DEFINITIONS.rates.decisionWinRate(effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.portfolioReduction(effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.averageReduction(effectiveShowYoY),
        COLUMN_DEFINITIONS.metrics.totalSavings,
        {
          ...COLUMN_DEFINITIONS.metrics.revenue,
          renderCell: (params) => {
            const isNassau = params.row?.path?.[0] === 'Nassau';
            const val = params.value || 0;
            const formattedValue = val.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            });
            const cell = renderWithYoYIfEnabled(
              effectiveShowYoY,
              { ...params, value: formattedValue },
              'revenue',
              'revenueYoY',
              false
            );
            return isNassau ? (
              <Tooltip
                title="This assumes a 40% fee retainer"
                disableInteractive
              >
                <span>
                  {cell}
                  <span style={{ color: 'orange' }}>*</span>
                </span>
              </Tooltip>
            ) : (
              cell
            );
          }
        }
      );
    } else if (groupBy === 'negotiator') {
      baseColumns.push(
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Settled', effectiveShowYoY),
        ...(caseType === 'SCAR'
          ? [
              COLUMN_DEFINITIONS.stats.scarSpecific.stipNC(effectiveShowYoY),
              COLUMN_DEFINITIONS.stats.scarSpecific.notInvoicable
            ]
          : []),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Withdrawn', effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.decisions(effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Won', effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Denied', effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.portfolioReduction(effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.winRate(caseType, effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.settlementRate(caseType),
        ...(caseType === 'SCAR'
          ? [COLUMN_DEFINITIONS.rates.decisionWinRate(effectiveShowYoY)]
          : []),
        COLUMN_DEFINITIONS.rates.averageReduction(effectiveShowYoY),
        COLUMN_DEFINITIONS.metrics.totalSavings,
        {
          ...COLUMN_DEFINITIONS.metrics.revenue,
          renderCell: (params) => {
            const val = params.value || 0;
            const formattedValue = val.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            });
            return renderWithYoYIfEnabled(
              effectiveShowYoY,
              { ...params, value: formattedValue },
              'revenue',
              'revenueYoY',
              false
            );
          }
        },
        {
          ...COLUMN_DEFINITIONS.metrics.getDollarsPerFile(caseType),
          renderCell: makeYoYRenderCell(
            effectiveShowYoY,
            'dollarsPerFile',
            'dollarsPerFileYoY',
            'currency',
            true
          )
        }
      );
    } else {
      // County or municipality (treeData)
      baseColumns.push(
        COLUMN_DEFINITIONS.stats.portfolioValidCases,
        COLUMN_DEFINITIONS.stats.stipulated(caseType, effectiveShowYoY),
        ...(caseType === 'SCAR'
          ? [COLUMN_DEFINITIONS.stats.scarSpecific.stipNC(effectiveShowYoY)]
          : []),
        ...(caseType === 'SCAR'
          ? [COLUMN_DEFINITIONS.stats.decisions(effectiveShowYoY)]
          : []),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Won', effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Denied', effectiveShowYoY),
        COLUMN_DEFINITIONS.stats.caseStatus(caseType, 'Withdrawn', effectiveShowYoY),

        // Always define these columns if SCAR – they will be initially hidden via columnVisibilityModel
        ...(caseType === 'SCAR'
          ? [
              COLUMN_DEFINITIONS.stats.scarSpecific.awaitingDecision,
              COLUMN_DEFINITIONS.stats.scarSpecific.adminHold,
              COLUMN_DEFINITIONS.stats.scarSpecific.notInvoicable
            ]
          : []
        ),

        COLUMN_DEFINITIONS.rates.averageReduction(effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.portfolioReduction(effectiveShowYoY),
        COLUMN_DEFINITIONS.rates.winRate(caseType, effectiveShowYoY),
        ...(caseType === 'SCAR'
          ? [COLUMN_DEFINITIONS.rates.decisionWinRate(effectiveShowYoY)]
          : []),
        COLUMN_DEFINITIONS.rates.settlementRate(caseType),
        taxesColumn,
        COLUMN_DEFINITIONS.metrics.totalSavings,
        {
          ...COLUMN_DEFINITIONS.metrics.revenue,
          renderCell: (params) => {
            const isNassau = params.row?.county === 'Nassau';
            const val = params.value || 0;
            const formattedValue = val.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            });
            const cell = renderWithYoYIfEnabled(
              effectiveShowYoY,
              { ...params, value: formattedValue },
              'revenue',
              'revenueYoY',
              false
            );
            return isNassau ? (
              <Tooltip
                title="This assumes a 40% fee retainer"
                disableInteractive
              >
                <span>
                  {cell}
                  <span style={{ color: 'orange' }}>*</span>
                </span>
              </Tooltip>
            ) : (
              cell
            );
          }
        },
        {
          ...COLUMN_DEFINITIONS.metrics.getDollarsPerFile(caseType),
          renderCell: makeYoYRenderCell(
            effectiveShowYoY,
            'dollarsPerFile',
            'dollarsPerFileYoY',
            'currency',
            true
          )
        }
      );
    }
    return baseColumns;
  }, [
    groupBy,
    caseType,
    effectiveShowYoY
  ]);

  const groupingColDef = useMemo(
    () => ({
      minWidth: 200,
      headerClassName: 'grouping-header',
      pinned: true,
      cellClassName: (params) => {
        if (params.id === 'totals') return '';
        if (groupBy === 'county') {
          if (params.row.path?.length === 1) return 'clickable-county';
          if (params.row.path?.length === 2) return 'clickable-muni';
        }
        return '';
      }
    }),
    [groupBy]
  );

  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex-1 flex items-center">
            <GridToolbar />
          </div>
          <div className="flex-1 flex justify-center">
            <Tabs
              value={activeYear}
              onChange={handleYearChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                minHeight: 36,
                '& .MuiTab-root': {
                  minHeight: 36,
                  padding: '6px 16px',
                  fontSize: '0.875rem'
                }
              }}
            >
              {availableYears.map(year => (
                <Tab key={year} label={year} value={year} sx={{ minWidth: 80 }} />
              ))}
            </Tabs>
          </div>
          <div className="flex-1 flex justify-end">
            <Tooltip 
              title={activeYear === '2023' ? "Choose a later year to enable YoY changes" : ""}
              arrow
            >
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showYoY && activeYear !== '2023'}
                      onChange={(e) => setShowYoY(e.target.checked)}
                      disabled={activeYear === '2023'}
                      size="small"
                    />
                  }
                  label="Display YoY Changes"
                  sx={{ mr: 1 }}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Box>
    );
  };

  const getTreeDataPath = useCallback((row) => {
    if (row.id === 'totals') return ['Totals'];
    return row.path;
  }, []);

  return (
    <Paper sx={{ width: '100%' }}>
      <div
        className="justify-center text-center text-xs items-center"
        style={{ marginTop: '4px' }}
      >
        *Prior assessments may be off in some BAR data until fixed.
      </div>

      <Box
        sx={{
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ flex: '0 1 300px', minWidth: 0 }}
        >
          <IconButton
            onClick={() => {
              if (selectedNegotiator || selectedMuniCode) {
                setSelectedNegotiator(null);
                setSelectedMuniCode(null);
                setGroupBy('county');
              } else {
                onBack();
              }
            }}
            size="small"
            sx={{ p: 0.5, flexShrink: 0 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: 'flex', alignItems: 'center', gap: '8px', minWidth: 0 }}
          >
            <span style={{ flexShrink: 0 }}>Aventine {taxYear} Results</span>
            {(selectedNegotiator || selectedMuniCode) && groupBy === 'municipality' && (
              <Typography
                variant="subtitle1"
                component="span"
                sx={{
                  color: 'text.secondary',
                  minWidth: '100px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                - {selectedNegotiator || 
                    (selectedMuniCode && 
                      (selectedMuniCode.length === 1 
                        ? COUNTY_CODES[selectedMuniCode]
                        : MUNICIPAL_CODES[selectedMuniCode.charAt(0)]?.[selectedMuniCode])
                      )}
              </Typography>
            )}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ flex: '2', justifyContent: 'center' }}
        >
          <ToggleButtonGroup
            value={groupBy}
            exclusive
            onChange={(event, newGrouping) => {
              if (newGrouping) {
                setGroupBy(newGrouping);
              }
            }}
            aria-label="grouping options"
            size="small"
          >
            <ToggleButton value="county">County</ToggleButton>
            <ToggleButton value="hearingOfficer" disabled={caseType === 'BAR'}>
              Hearing Officer
            </ToggleButton>
            <ToggleButton value="negotiator" disabled={caseType === 'BAR'}>
              Negotiator
            </ToggleButton>
            <ToggleButton value="municipality">
              {selectedMuniCode || selectedNegotiator ? 'Filtered Data' : 'All Data'}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ flex: '1', justifyContent: 'flex-end' }}
        >
          <ToggleButtonGroup
            value={caseType}
            exclusive
            onChange={(event, newType) => {
              if (newType) {
                setCaseType(newType);
                // Reset groupBy to county and clear selected negotiator when switching case types
                setGroupBy('county');
                setSelectedNegotiator(null);
                setSelectedMuniCode(null);
              }
            }}
            size="small"
          >
            <ToggleButton value="SCAR">SCAR</ToggleButton>
            <ToggleButton value="BAR">BAR</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {groupBy === 'county' ? (
          <DataGridPro
            apiRef={treeApiRef}
            rows={renderedRows}
            columns={columns}
            treeData
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            initialState={{
              pinnedColumns: {
                left: ['__tree_data_group__']
              }
            }}
            defaultGroupingExpansionDepth={0}
            pagination={false}
            density="compact"
            slots={{ toolbar: CustomToolbar }}
            disableDensitySelector
            disableColumnMenu={false} 
            pinnedRows={totalsRow ? { bottom: [totalsRow] } : undefined}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            sx={{
              '& .clickable-muni': {
                color: 'primary.main',
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                  backgroundColor: 'action.hover'
                }
              },
              '& .clickable-county': {
                textDecoration: 'underline',
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                  backgroundColor: 'action.hover'
                }
              },
              '& .zero-value': {
                color: 'text.disabled'
              },
              '& [data-id="totals"]': {
                fontWeight: 'bold',
                '& .MuiDataGrid-cell': {
                  fontWeight: 'bold'
                }
              },
              flex: 1,
              height: 675
            }}
          />
        ) : (
          <DataGridPro
            key="non-tree-grid"
            apiRef={gridApiRef}
            rows={renderedRows}
            columns={columns}
            disableDensitySelector
            pagination
            pageSize={25}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableRowSelectionOnClick
            density="compact"
            disableColumnMenu={false}
            slots={{ toolbar: CustomToolbar }}
            pinnedRows={(groupBy === 'hearingOfficer' || groupBy === 'negotiator') && totalsRow ? { bottom: [totalsRow] } : undefined}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            initialState={{
              pinnedColumns: {
                left: [groupBy === 'hearingOfficer' ? 'SCARHearingOfficer' : 'Negotiator']
              }
            }}
            sx={{
              '& .clickable-cell': {
                color: 'primary.main',
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                  backgroundColor: 'action.hover'
                }
              },
              '& .zero-value': {
                color: 'text.disabled'
              },
              '& [data-id="totals"]': {
                backgroundColor: 'common.white',
                '& .MuiDataGrid-cell': {
                  fontWeight: '700 !important'
                }
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: 'common.white',
                '& .MuiIconButton-root.Mui-disabled': {
                  backgroundColor: 'transparent !important',
                  '& .MuiSvgIcon-root': {
                    color: 'rgba(0, 0, 0, 0.26)'
                  }
                }
              },
              flex: 1,
              height: 675,
              minHeight: 675,
              maxHeight: 675
            }}
          />
        )}
      </Box>
    </Paper>
  );
}

export default SettlementTable;

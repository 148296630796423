import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { DataGridPro, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid-pro';
import { usePersistedDataStore, useLocalDataStore } from '../store';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DownloadButton from './DownloadButton.js';


const CaseNavigation = React.memo(({ address, compNumber, loading }) => {
  const setReviewPage = useLocalDataStore((state) => state.setReviewPage);
  const navigate = useNavigate();

  const handleClick = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('comp', compNumber);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    setReviewPage(false);
    navigate(newUrl);
  };

  return (
<span
      onClick={handleClick}
      style={{
        textDecoration: loading ? 'none' : 'underline',
        color: loading ? 'gray' : 'blue',
        cursor: 'pointer',
        fontStyle: loading ? 'italic' : 'normal',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <>
          {/* <CircularProgress size={16} style={{ marginRight: '4px' }} /> */}
          Loading...
          <CircularProgress size={16} style={{ marginLeft: '4px' }} />
        </>
      ) : (
        address
      )}
    </span>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.address === nextProps.address &&
    prevProps.compNumber === nextProps.compNumber &&
    prevProps.loading === nextProps.loading
  );
});


// Update this so there is a value for if ANY of the isloading states are still true, if so fire teh callback.
function SummaryCaseReviewTable({
  handleCellClick,
  selectionModel,
  updatedArray,
  filteredNegotiationCases,
  handleUpdateStateCallback,
  // updateNegotiation,
  negotiationCases,
  fileName,
  keys,
}) {
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  // const getNegotiationObj
  const getRar = usePersistedDataStore((state) => state.rar);
  
  // Use shallow comparison for getCompSheet length
  const isLoading = useMemo(
    () => getCompSheet.length < negotiationCases.length,
    [getCompSheet?.length, negotiationCases.length] // Only depend on lengths
  );

  // Memoize the callback ref to prevent unnecessary updates
  const callbackRef = useRef(handleUpdateStateCallback);
  useEffect(() => {
    callbackRef.current = handleUpdateStateCallback;
  }, [handleUpdateStateCallback]);

  // Effect for handling the interval
  useEffect(() => {
    let intervalId;
    if (isLoading) {
      intervalId = setInterval(() => {
        if (callbackRef.current) {
          callbackRef.current();
        }
      }, 500);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isLoading]);

  // Memoized headers```
  const headers = useMemo(() => {
    const defaultHeaders = [
      { field: 'id', headerName: '#', type: 'number', align: 'center', headerAlign: 'center', maxWidth: 50 },
      // Address
      {
        field: 'Address',
        headerName: 'Address',
        align: 'left',
        minWidth: 120,
        flex: 1,
        renderCell: ({ row }) => <CaseNavigation address={row.Address} loading={row.loading} compNumber={row.Comp} />,
      },
      { field: 'parcel_id', headerName: 'PID', type: 'string', align: 'left', flex: 0.6 },
      { field: 'ClientName', headerName: 'Client', type: 'string', align: 'left', flex: 0.6 },
      // scar id
      {
        field: 'SCARID',
        headerName: 'SCAR Id',
        type: 'string',
        align: 'left',
        flex: 0.6,
        renderCell: ({ row }) => <span>{row.SCARID || <span style={{ color: '#888', fontStyle: 'italic' }}>None</span>}</span>,
      },
      { field: 'PropertyAssessment', headerName: 'Assessment', type: 'string', align: 'left', flex: 0.6, valueFormatter: (value) => (value.toLocaleString()) },
      { field: 'IFMV', headerName: 'IFMV', type: 'number', align: 'left', headerAlign:'left', flex: 0.6, valueFormatter: (value) => `$${Math.round(value).toLocaleString()}` },
      {
        field: 'MarketValue',
        headerName: 'Workup Value',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        flex: 0.6,
        renderCell: ({ row }) => (
          <span>
            {row.MarketValue || <span style={{ color: '#888', fontStyle: 'italic' }}>None</span>}
          </span>
        ),
      },
      // Settlement, scar determination value (or BAR)
      {
        field: 'SCARDeterminationValue',
        headerName: 'Settlement Value',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        flex: 0.6,
        renderCell: ({ row }) => (
          <span style={{ 
            color: row.loading ? '#888' : row.SCARDeterminationValue !== null ? 'black' : '#888', 
            fontStyle: row.loading || row.SCARDeterminationValue === null ? 'italic' : 'normal' 
          }}>
            {row.loading
              ? 'Loading...'
              : row.SCARDeterminationValue !== null
              ? row.SCARDeterminationValue
              : 'None'}
          </span>
        ),
      },

      // PCT Reduction
      {
        field: 'pctReduction',
        headerName: 'Pct Reduction',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        flex: 0.6,
        renderCell: ({ row }) => (
          <span style={{ 
            color: row.loading ? '#888' : row.pctReduction !== null ? (row.pctReduction >= 0 ? 'red' : 'green') : '#888', 
            fontStyle: row.loading || row.pctReduction === null ? 'italic' : 'normal' 
          }}>
            {row.loading
              ? 'Loading...'
              : row.pctReduction !== null
              ? `${row.pctReduction.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}%`
              : 'None'}
          </span>
        ),
      },
      {
        field: 'OfferValue',
        headerName: 'Offer',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        flex: 0.6,
        renderCell: ({ row }) => (
          <span style={{ 
            color: row.loading ? '#888' : row.OfferValue !== null ? 'black' : '#888', 
            fontStyle: row.loading || row.OfferValue === null ? 'italic' : 'normal' 
          }}>
            {row.loading
              ? 'Loading...'
              : row.OfferValue !== null
              ? row.OfferValue
              : 'None'}
          </span>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        align: 'left',
        flex: 0.6,
        renderCell: ({ row }) => <span style={row.statusStyle}>{row.statusDisplay}</span>,
      },
    ];
  
    // Always include the "Rep ID" column before the "Status" column - this is no longer necessary
    defaultHeaders.splice(defaultHeaders.findIndex((col) => col.field === 'status'), 0, {
      field: 'RepID',
      headerName: 'Rep ID',
      type: 'string',
      align: 'center',
      flex: 0.6,
    });
  
    return defaultHeaders;
  }, []);
  
  // Memoize the createRows function itself
  const memoizedCreateRows = useCallback(
    (cases, fullNegotiationCases) => {
      return cases.map((currentCase, index) => {
        const fullIndex = fullNegotiationCases.findIndex(
          (c) => c.parcel_id === currentCase.parcel_id
        );
        const compSheetEntry = getCompSheet[fullIndex];
        const isLoading = !compSheetEntry;
        
        // Extract only needed data from getCompSheet
        const rar = compSheetEntry?.RAR?.[0] ?? null;
        
        // Determine if SCAR or BAR values should be used
        const isSCARFiled = currentCase.RepID !== "" && currentCase.RepID != null || currentCase.SCARFiled === 1;
        
        // Determine the appropriate SCARDeterminationValue
        const SCARDeterminationValue = isSCARFiled
          ? currentCase.SCARDeterminationValue
          : currentCase.BARDeterminationValue;
                // let pctReduction = null;
    
        // Settled field based on SCAR or BAR logic
        const settled = isSCARFiled
          ? ['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction)
          : `BAR ${currentCase.BARDeterminationAction}`;

          const safeScarDetValuePct = rar ? ((SCARDeterminationValue / rar - currentCase.IFMV) / currentCase.IFMV) : 0;
    
        // Pre-compute percentage reduction
        let pctReduction = currentCase.IFMV
          ? SCARDeterminationValue
            ? safeScarDetValuePct * 100
            : currentCase.OfferValue
            ? ((currentCase.OfferValue - currentCase.IFMV) / currentCase.IFMV) * 100
            : currentCase.SubjectMarketValue
            ? ((currentCase.SubjectMarketValue - currentCase.IFMV) / currentCase.IFMV) * 100
            : null
          : null;
    
        if (pctReduction !== null) {
          pctReduction = Math.abs(pctReduction) < 0.01 ? 0 : parseFloat(pctReduction.toFixed(2));
        }
    
        // Pre-compute formatted values
        const formattedMarketValue = currentCase.SubjectMarketValue
          ? `$${Math.round(currentCase.SubjectMarketValue).toLocaleString()}`
          : null;
        const formattedOfferValue = currentCase.OfferValue
          ? `$${currentCase.OfferValue.toLocaleString()}`
          : null;
        const formattedSCARDeterminationValue = 
        getRar && SCARDeterminationValue
          ? `$${Math.round(SCARDeterminationValue / getRar).toLocaleString()}`
          : null;
    
        // Status display
        let statusDisplay = 'Open';
        let statusStyle = { fontWeight: 'bold', color: 'black' };
    
        if (settled === true) {
          statusDisplay = 'Settled';
          statusStyle = { color: 'green', fontWeight: 'normal' };
        } else if (typeof settled === 'string' && settled.startsWith('BAR')) {
          statusDisplay = 'BAR Only';
          statusStyle = { color: 'black', fontWeight: 'normal' };
        }
    
        // Return preprocessed row object
        return {
          id: index + 1,
          Comp: fullIndex + 1,
          loading: isLoading,
          Address: currentCase.Address,
          parcel_id: currentCase.parcel_id,
          ClientName: currentCase.ClientName,
          Town: currentCase.Town,
          ZipCode: currentCase.ZipCode,
          PropertyAssessment: currentCase.PropertyAssessment,
          OfferValue: formattedOfferValue,
          IFMV: currentCase.IFMV,
          SCARID: currentCase.SCARIndexNumber,
          MarketValue: formattedMarketValue,
          pctReduction,
          SalePrice: currentCase.SalePrice,
          SaleDate: currentCase.SaleDate,
          RAR: getRar,
          RepID: currentCase.RepID,
          SCARDeterminationAction: isSCARFiled
            ? currentCase.SCARDeterminationAction
            : currentCase.BARDeterminationAction,
          SCARDeterminationValue: formattedSCARDeterminationValue,
          statusDisplay,
          statusStyle,
        };
      });
    },
    [getCompSheet, getRar] // Only depend on these values
  );

  // Use memoizedCreateRows in the rows memo
  const rows = useMemo(
    () => memoizedCreateRows(filteredNegotiationCases, negotiationCases),
    [
      memoizedCreateRows,
      filteredNegotiationCases,
      negotiationCases,
      updatedArray
    ]
  );

  const columnVisibilityModel = useMemo(() => {
    const allRepIdsBlank = rows.every((row) => row.RepID === "");
    return { RepID: !allRepIdsBlank };
}, [rows]);

  const tableProps = useMemo(() => ({
    density: 'compact',
    disableRowSelectionOnClick: true,
  }), []);
  const isFirstRender = useRef(true);
  const [loading, setLoading] = React.useState(true);
    
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setTimeout(() => {
        setLoading(false);
      }, 500); // Simulate loading delay
    }
  }, []);

  return (
    <div className="flex-grow h-full overflow-auto">
      {/* Show loading spinner overlay while data is loading */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <div className="bg-white w-full h-[600px]">
          <DataGridPro
            {...tableProps}
            rows={rows}
            columns={headers}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onRowSelectionModelChange={handleCellClick}
            columnVisibilityModel={columnVisibilityModel}
            rowSelectionModel={selectionModel}
            localeText={{ noRowsLabel: 'No cases match criteria' }}
            disableColumnMenu
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                fileName,
                keys,
                caseData: negotiationCases,
                selectedRows: selectionModel.map(id => id - 1)
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

function CustomToolbar(props) {
  const { fileName, keys, caseData, selectedRows } = props;
  return (
    <div style={{ padding: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <GridToolbarQuickFilter />
      <DownloadButton
        caseData={caseData}
        fileName={fileName}
        selectedRows={selectedRows}
        keys={keys}
      />
    </div>
  );
}

SummaryCaseReviewTable.whyDidYouRender = true;
export default React.memo(SummaryCaseReviewTable);

import { usePersistedDataStore, useLocalDataStore } from '../components/store';
import { useState, useCallback, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

export const fields = [
  { field: 'id', headerName: '#', width: 80, type: 'string' },
  { field: 'parcel_id', headerName: 'Parcel ID', type: 'string' },
  { field: 'Address', headerName: 'Address', type: 'string' },
  { field: 'Town', headerName: 'Town', type: 'string' },
  { field: 'ZipCode', headerName: 'Zip Code', type: 'string' },
  { field: 'IFMV', headerName: 'IFMV', type: 'numeric' },
  { field: 'Taxes', headerName: 'Taxes', type: 'numeric' },
  { field: 'WorkupValueAvg', headerName: 'Workup Value Avg', type: 'numeric' },
  { field: 'WorkupValueMid', headerName: 'Workup Value Mid', type: 'numeric' },
  { field: 'WorkupValueAvgOptim', headerName: 'Workup Value Avg (Optimized)', type: 'numeric' },
  { field: 'WorkupValueMidOptim', headerName: 'Workup Value Mid (Optimized)', type: 'numeric' },
  // this value is PRE heuristics
  { field: 'KNNWorkupValue', headerName: 'KNN Workup Value', type: 'numeric' },
  // this value is POST heuristics
  { field: 'MarketingValuePrediction', headerName: 'Marketing Value Prediction', type: 'numeric' },
  { field: 'ProjectedPotentialSavings', headerName: 'Projected Potential Savings', type: 'numeric' },
  { field: 'ProjectedAventineRevenue', headerName: 'Projected Aventine Revenue', type: 'numeric' },
  { field: 'TaxReductionPct', headerName: 'Tax Reduction Pct', type: 'percent' },
  { field: 'CaseDescription', headerName: 'Case Description', type: 'string' },
  { field: 'SalePrice', headerName: 'Sale Price', type: 'numeric' },
  { field: 'SalePrice_pred', headerName: 'Sale Price Prediction', type: 'numeric' },
  { field: 'Total_Possible_Neighbors', headerName: 'Total Possible Neighbors', type: 'numeric' },
  { field: 'Total_Regular_sales', headerName: 'Total Regular Sales', type: 'numeric' },
  { field: 'Total_Optimize_Sales', headerName: 'Total Optimize Sales', type: 'numeric' },

  { field: 'average_index_optim', headerName: 'Our Average Index', type: 'numeric' },
  { field: 'average_index_opp', headerName: 'Town Average Index', type: 'numeric' },
  // Percentage of the 
  { field: 'KNNRegOptimWorkupRatio', headerName: 'Reg-Optim Workup Ratio', type: 'percent' },

  { field: 'PropertyAssessment', headerName: 'Property Assessment', type: 'numeric' },
  { field: 'SaleDate', headerName: 'Sale Date', type: 'string' },
  { field: 'Name', headerName: 'Name', type: 'string' },
  { field: 'FirstName', headerName: 'First Name', type: 'string' },
  { field: 'LastName', headerName: 'Last Name', type: 'string' },
  { field: 'SettledMktVal', headerName: 'Settled Market Value', type: 'numeric' },
  // letter type
  { field: 'LetterType', headerName: 'Letter Type', type: 'string' },
  { field: 'URL', headerName: 'URL', type: 'string' },
  { field: 'NassauPID', headerName: 'Nassau PID', type: 'string' },
];

export function useStreamProcessorNew(setFetchIterationCallback) {
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const setEvalDate = usePersistedDataStore((state) => state.setEvalDate);
  const setRar = usePersistedDataStore((state) => state.setRar);
  const setEq = usePersistedDataStore((state) => state.setEq);
  const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
  const getMarketingResponse = usePersistedDataStore((state) => state.marketingResponse);
  const setMarketingResponse = usePersistedDataStore((state) => state.setMarketingResponse);
  const setTotalCases = usePersistedDataStore((state) => state.setTotalCases);
  // These two objects - if you can remove I think would be a good optimization.
  // these update with relative freuqency and cause you to need to recreate this function
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const [loading, setLoading] = useState(false);

  const iterationRef = useRef(0);
  const marketingResponseRef = useRef([]); // Stores the cumulative marketing responses

  const processFetchResponse = useCallback(({ response, compStreamObjectRef, negotiationObj, currentIndex, ManualReview, }) => {
    console.log('processing fetch stream response')
    const regularComps = [response[0]];
    const optimizedComps = [response[1]];
    const evalDate = response[3];

      const inventoryUpdate = negotiationObj.inventoryUpdateRun ? true : false; 
      // console.log('is it an inventory update?',inventoryUpdate)
      const lookupArray = inventoryUpdate ? negotiationObj.originalPidOrder: negotiationObj.uids;
      // console.log('orig pid order',negotiationObj.originalPidOrder)
      // console.log('uid order',negotiationObj.uids)
      // console.log('the original lookup array to find index',lookupArray)
      // console.log('inventory update?',inventoryUpdate)
      const updatedArray = compStreamObjectRef?.updated || new Array(lookupArray.length).fill(false); // use the existing array
    // Determine whether to use negotiationObj.uids or negotiationObj.cases
    // Map over response[0] and update the updatedArray
      const parcelId = response[0].parcel_id[0]; // extract parcel_id[0] // parcel id should be the parcel ID of hte subject in question?
      // console.log('getting back this pid first',parcelId)
      // console.log('parcel id',parcelId)
      const indexInLookupArray = lookupArray.findIndex(item => item === parcelId);
      // console.log('what is the original index of this client?',indexInLookupArray)

      // This whole piece should be handled in the callback function in whatever component is invoking this, not in this stream parser itself?
    // if (negotiationObj.manualReview === 1) {
      if (currentIndex === 1) {
        compStreamObjectRef.properties = [regularComps, optimizedComps];
        // if(inventoryUpdate){
        compStreamObjectRef.properties = [
          new Array(lookupArray.length).fill(null), // First nested array
          new Array(lookupArray.length).fill(null)  // Second nested array
        ];
        compStreamObjectRef.updated = new Array(lookupArray.length).fill(false);
        compStreamObjectRef.updated[indexInLookupArray] = true;
        updatedArray[indexInLookupArray] = true;

        // Ensure getCompSheet and getOptimizedComps are defined and initialized
        // Update specific indices within compStreamObjectRef
        compStreamObjectRef.properties[0][indexInLookupArray] = response[0];
        compStreamObjectRef.properties[1][indexInLookupArray] = response[1];

        const updatedRegularComps = getCompSheet ? cloneDeep(getCompSheet) : [];
        const updatedOptimizedComps = getOptimizedComps ? cloneDeep(getOptimizedComps) : [];
      //   // Update local copies
        updatedRegularComps[indexInLookupArray] = response[0];
        updatedOptimizedComps[indexInLookupArray] = response[1];
        if(!inventoryUpdate){
          setCompSheet(updatedRegularComps);
          setOptimizedComps(updatedOptimizedComps);
          setRar(regularComps[0].RAR[0]);
          setEq(regularComps[0].EQ[0]);
          setEvalDate(evalDate);
        }

        //  non INVENTORY SPECIFIC FUNCTIONALITY:
        // if(!inventoryUpdate){
        //   setCompSheet(updatedRegularComps);
        //   setOptimizedComps(updatedOptimizedComps);
        //   setRar(regularComps[0].RAR[0]);
        //   setEq(regularComps[0].EQ[0]);
        //   setEvalDate(evalDate);
        // }
          // setReviewPage(true)
        } 
      //   else if (currentIndex === 2){
      //   const updatedRegularComps = getCompSheet ? cloneDeep(getCompSheet) : [];
      //   const updatedOptimizedComps = getOptimizedComps ? cloneDeep(getOptimizedComps) : [];
      //   // Update local copies
      //   updatedRegularComps[indexInLookupArray] = response[0];
      //   updatedOptimizedComps[indexInLookupArray] = response[1];
      //   if(!inventoryUpdate){
      //     setCompSheet(updatedRegularComps);
      //     setOptimizedComps(updatedOptimizedComps);
      //     setRar(regularComps[0].RAR[0]);
      //     setEq(regularComps[0].EQ[0]);
      //     setEvalDate(evalDate);
      //   }
      // }
        // if a different index than 1:
      else { // this navigation should only really happen in knn run not in the update inventory.
        // if(inventoryUpdate){ // only update invdividual entry if you're in the inventory update.
          // Every one done here shoul be flagged as updated FALSE
          updatedArray[indexInLookupArray] = true;
          compStreamObjectRef.properties[0][indexInLookupArray] = response[0]
          compStreamObjectRef.properties[1][indexInLookupArray] = response[1]
      }
    //   REVISIT THIS AFTER
      // non-manual review processing function: -- nEED TO FIX
    // } else {
    //   console.log(`processing non-manual review, batch number ${currentIndex}`)
    //   // console.log('no manual review')
    //   if (!compStreamObjectRef || !compStreamObjectRef.properties || compStreamObjectRef.properties.length === 0) {
    //     console.log('no compref, instantiating')
    //     compStreamObjectRef.properties = [regularComps, optimizedComps];
    //   } else {
    //     console.log(' the appended comps')
    //     console.log(compStreamObjectRef.properties[0].concat(regularComps))
    //     compStreamObjectRef.properties[0] = compStreamObjectRef.properties[0].concat(regularComps);
    //     compStreamObjectRef.properties[1] = compStreamObjectRef.properties[1].concat(optimizedComps);
    //   }
    // }

    // Update fetch iteration ref and state
    iterationRef.current = currentIndex;

    // if(inventoryUpdate){ // flag this to be true
      compStreamObjectRef.updated[indexInLookupArray] = true;
    // }
    // Make this a hybrid so that there is a 'set updated'
    if (setFetchIterationCallback) {
      // if(inventoryUpdate){ // pass back the actual index if its the update version
      // console.log('passing in: ',indexInLookupArray)
        setFetchIterationCallback(indexInLookupArray);
      // }else{
      // setFetchIterationCallback(iterationRef.current);
      // }
    } else {
      console.warn('setFetchIterationCallback is not provided');
    }

  }, [setCompSheet, loading, setOptimizedComps, setFetchIterationCallback, getCompSheet, getOptimizedComps]);

const processMarketingResponse = useCallback(
  ({ response, compStreamObjectRef, negotiationObj, currentIndex, ManualReview }) => {
    const marketingResponse = response[0];
    // console.log('marketing response',marketingResponse)
    const totalCases = response[3];
    // const updatedMarketingResponse = cloneDeep(marketingResponseRef.current);

    // set your total cases state if it sthe first one.

    // Helper to create a row object
    const createRowFromResponse = (response, index) => {
      return fields.reduce(
        (row, { field }) => ({
          ...row,
          id: index + 1, // Set the unique `id` for the DataGridPro
          [field]: response[field] ?? 'Loading...', // Default to 'Loading...' if no value
        }),
        {}
      );
    };
    

    // Initialize the marketing response array
    if (currentIndex === 1) {
      marketingResponseRef.current = Array(totalCases).fill(null); // Initialize with `null`
      setTotalCases(totalCases);
    }

    // Update the current index with a valid row
    if (marketingResponse) {
      marketingResponseRef.current[currentIndex - 1] = createRowFromResponse(marketingResponse, currentIndex - 1);
      setMarketingResponse(marketingResponseRef.current.filter((row) => row !== null)); // Filter out nulls
    }

    // Update marketingResponse array on every 10th iteration or at the last case
    // if (currentIndex % 100 === 0 || currentIndex === totalCases) {

    // }

    // Update iteration reference
    iterationRef.current = currentIndex;

    // Trigger fetch iteration callback if set
    if (setFetchIterationCallback) {
      setFetchIterationCallback(currentIndex);
    }
  },
  [setFetchIterationCallback]
);



  const processStream = useCallback(async ({ compStreamObjectRef, stream, negotiationObj, resetIteration=false }) => {
    setLoading(true);
    try {
      const reader = stream.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = '';
      // Reset your count if you re-call function (ie: from inventory)
      let iterationCount = resetIteration ? 0 : iterationRef.current;

      while (true) { 
        const { done, value } = await reader.read();
        if (done) {
          // console.log('Stream completed');
          iterationRef.current = 0;
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        const parts = buffer.split('\n');
        // console.log(parts)
        buffer = parts.pop();
        
        for (const part of parts) {
          if (part.trim() !== "") {
            try {
              const update = JSON.parse(part);
              // console.log('the update going into function')
              // console.log(update)
              // console.log('the reportType',negotiationObj)
              // this is the json parsed response for each index in the array.
              iterationCount += 1;
              // console.log('processing marketing update for iteration',iterationCount)

              // console.log("Processing stream update:", update);
              if(negotiationObj.reportType==='marketing'){
                // console.log('running marketing parsing')
                // processMarketingResponse
                processMarketingResponse({
                  response: update,
                  compStreamObjectRef: compStreamObjectRef,
                  negotiationObj: negotiationObj,
                  currentIndex: iterationCount,
                });
              }else{
                // regular fetch parsing (used for sales run / equity runs, or inventory updates)
              processFetchResponse({
                response: update,
                compStreamObjectRef: compStreamObjectRef,
                negotiationObj: negotiationObj,
                currentIndex: iterationCount,
              });
              }
            } catch (err) {
              console.error("Error parsing JSON:", err, part);
            }
          }
        }
      }

      // if only one thing comes back, run this. Really kind of dumb code. Only runs the comp generation code here.
      if (buffer.trim() !== "") {
        console.log('the final buffer is running (this shouldnt happen')
        try {
          const finalUpdate = JSON.parse(buffer);
          // console.log("Final update from stream:", finalUpdate);
          processFetchResponse({
            response: finalUpdate,
            globalCompRef: compStreamObjectRef,
            negotiationObj: negotiationObj,
            currentIndex: iterationCount,
            finalUpdate: true,
          });
        } catch (err) {
          console.error("Error parsing final buffer JSON:", err, buffer);
        }
      }
      console.log('Stream is finished.');
      setIsFetching(false);
      setLoading(false);
    } catch (error) {
      console.error("Error in stream processing:", error);
      toast.error("Error processing stream data",error,
        {
          autoClose: false,      // Prevents automatic dismissal
          closeOnClick: true,    // Allows user to click to dismiss
          draggable: true,       // Optional: allows dragging to close
          // pauseOnHover: true,    // Optional: keeps toast open when hovering
      });
    } finally {
      setLoading(false);
    }
  }, [processFetchResponse]);

  return {
    processStream,
    loading, // This will now reflect the current iteration count in the invoking component
    // You should return your compref here? unless you can just reference it.
  };
}

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ProprietyLogo from '../common/ProprietyLogo';
// import { useAuth } from './AuthContextProvider';
import { usePersistedDataStore } from './store';
import CircularProgress from '@mui/material/CircularProgress';
import Haverstraw from '../images/Haverstraw.png';
import {useUser} from './UserContext'
import {useNavigate} from 'react-router-dom'
import axiosInstance from '../axiosConfig.js'
import {toast} from 'react-toastify'


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user, logout, loading } = useUser();
  const navigate = useNavigate();
  // const {logoutAndNavigate} = useAuth();
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);
  const location = useLocation(); // Get the current location
  // abstract this into a higher level thing on load (like auth context)
  const darkMode = false;

  // const restrictedEmails = ['
  const isRestricted = user?.userAccess?.level !== 'admin' && user?.userAccess?.level !== 'aventine';


  async function handleClearCache() {
    const response = await axiosInstance.get('/clear_cache')
    console.log(response)
      if(response.data === 'OK'){
        toast.success('Cache Cleared')
      }
  }

  const pages = user
    ? [
        // { page: 'Home', route: '/' },
        { page: 'Analysis', route: '/analysis', isLoading: getIsFetching },
        { page: 'Graphs', route: '/graph', disabled: isRestricted },
      ]
    : [{ page: 'Home', route: '/' }];

  const userSettings = [
    {
      label: 'Profile',
      action: () => console.log('/profile'),
      disabled: true,
    },
    {
      label: 'Clear Cache',
      action: handleClearCache,
    },
    {
      label: 'Logout',
      action: async () => {
        navigate('/signin');
        await logout();
      },
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: darkMode ? '#333' : 'white',
        boxShadow: 3,
        color: darkMode ? 'white' : 'black',
      }}
    >
      <Container maxWidth="xl" className="z-[999]">
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
          className="justify-between"
        >
          {/* Propriety Logo */}
          <div className="hidden md:flex items-center h-full">
            <ProprietyLogo style={{ height: '100%' }} />
          </div>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map(({ page, route }) => (
                <MenuItem
                  key={page}
                  component={NavLink}
                  to={`${route}${location.search}`}
                  // to={route}
                  onClick={handleCloseNavMenu}
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Desktop Navigation */}
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
            className="ml-4 hidden md:flex"
          >
            {pages.map(({ page, route, isLoading, disabled }) => (
              <Button
                key={page}
                component={NavLink}
                to={`${route}${location.search}`}
                disabled={disabled}
                disableRipple
                endIcon={
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      ml: '0 !important',
                    }}
                  >
                    {getIsFetching && isLoading && <CircularProgress size={12} />}
                  </Box>
                }
                sx={{
                  ml: 1,
                  textDecoration: 'none',
                  color: 'black',
                  '&.active': { color: 'primary.main' },
                  '&:hover': { backgroundColor: 'transparent', color: 'primary.main' },
                  padding: '6px 6px',
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {/* User Menu */}
          {user ? (
  // Display user profile if signed in
  <Box sx={{ flexGrow: 0 }} className="flex items-center gap-2">
    <Box>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        {user.userName}
      </Typography>
      <Typography
        sx={{
          fontSize: '0.625rem',
          color: 'gray',
        }}
      >
        {user.organization}
      </Typography>
    </Box>
    <Tooltip title="Open settings">
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar alt={user.userName} src={Haverstraw} />
      </IconButton>
    </Tooltip>
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {userSettings.map((setting) => (
        <MenuItem
          key={setting.label}
          onClick={() => {
            setting.action();
            handleCloseUserMenu();
          }}
          disabled={setting.disabled}
        >
          <Typography sx={{ textAlign: 'center' }}>{setting.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  </Box>
) : (
  // Display CircularProgress or "Sign In" button based on loading state
  // Basically abandoned this. should be set based on user actually logging in, not the initial load.
  loading ? (
    <CircularProgress size={20} />
  ) : (
    <Tooltip title="Sign In">
      <Button component={NavLink} to="/signin" variant="contained">
        Sign In
      </Button>
    </Tooltip>
  )
)}

        </Toolbar>
      </Container>
    </AppBar>
  );
}


export default React.memo(ResponsiveAppBar);

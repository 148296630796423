import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { usePersistedDataStore } from '../store.js';
import SummaryCaseReviewTable from './SummaryCaseReviewTable';

function ReviewSettlements({ handleUpdateStateCallback, updatedArray, negotiationCases, filterType, setFilterType, filteredNegotiationCases}) {
  
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const municipalityString = queryParams.get('municipalityString') || 'All';

  // const totalIFMV = useMemo(() => {
  //   if(!negotiationCases) return 0;
  //   return negotiationCases.reduce((acc, item) => {
  //     if (item && item.IFMV) {
  //       return acc + item.IFMV;
  //     }
  //     return acc;
  //   }, 0);
  // }, []);

  // // Summary data for Haverstraw
  // const summaryData = {
  //   totalPropertyValue: 3855738615,
  //   totalPropertyTax: 36558951,
  //   annualTrend: 0.04545,
  //   propertiesGrieved: Math.round(totalIFMV),
  // }

  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
  const [selectionModel, setSelectionModel] = React.useState([]);

    // For the download
    const keys = ['parcel_id', 'Address', 'Town', 'ZipCode', 'ClientName', 'SCARIndexNumber','PropertyAssessment', 'IFMV', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'Taxes', 'SubjectMarketValue', 'OfferValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'settled', 'Comp1', 'Comp2', 'CaseNotes', 'RepID'];
    
    const handleCellClick = useCallback((selection) => {
      console.log("Row ID:", selection);
      setSelectionModel(selection);
    }, [selectionModel]);

    console.log('negotiationCases', negotiationCases)
    return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
<div className="h-full flex flex-col">
  <div className="flex flex-col flex-grow h-0">
    {/* Filtering and Download Div */}
    {/* Case Review Table */}    
    <SummaryCaseReviewTable
      updatedArray={updatedArray}
      handleCellClick={handleCellClick}
      selectionModel={selectionModel}
      filteredNegotiationCases={negotiationCases}
      negotiationCases={negotiationCases}
      handleUpdateStateCallback={handleUpdateStateCallback}
      fileName={fileName}
      keys={keys}
    />
  </div>
  
</div>
  );
} export default ReviewSettlements

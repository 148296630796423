import React from 'react';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePersistedDataStore, useLocalDataStore } from '../store';

export default function BackToAnalysisButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const setTimeAdjustmentSettings = usePersistedDataStore((state) => state.setTimeAdjustmentSettings);
  const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
  const getReportType = usePersistedDataStore((state) => state.reportType);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const setReviewPage = useLocalDataStore((state) => state.setReviewPage);
  const getReviewPage = useLocalDataStore((state) => state.reviewPage);

  // Get URL parameters
  const queryParams = new URLSearchParams(location.search);
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const courtDate = queryParams.get('CourtDate') || '';
  const taxYear = queryParams.get('TaxYear') || '';
  const comp = queryParams.get('comp');

  const handleBackToAnalysis = () => {
    if (getCompSheet?.length > 0 && comp) {
      // If we have comp data and are viewing a specific comp, go back to review page
      queryParams.delete('comp');
      setTimeAdjustmentSettings(null);
      setIsFetching(false);
      setReviewPage(true);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    } else {
      // Otherwise go back to analysis page
      queryParams.delete('comp');
      setTimeAdjustmentSettings(null);
      setIsFetching(false);
      navigate(`/analysis?${queryParams.toString()}`);
    }
  };

  return (
    <Button
      variant='outlined'
      sx={{ padding: '2px 8px' }}
      startIcon={<KeyboardBackspaceIcon />}
      onClick={handleBackToAnalysis}
    >
      {getReportType !== 'marketing' && !getReviewPage && (courtDate !== '' || MuniCode !== 'All') && (
        <li className={`flex ${courtDate !== '' ? '' : 'text-center items-center mr-0.5'}`}>
          {courtDate !== '' ? (
            <>
              {courtDate} - <span className="text-primary ml-1">{MuniCode + ' (' + taxYear + ')'}</span>
            </>
          ) : (
            <div id="text" className="text-[#1976d2] font-medium" style={{ lineHeight: '1' }}>
              {MuniCode + ' (' + taxYear + ') '}
            </div>
          )}
        </li>
      )}
      {getReviewPage ? 'Run Analysis' : getCompSheet?.length > 0 ? ' Summary' : ' Run Analysis'}
    </Button>
  );
}
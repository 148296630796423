import { useState, useEffect, createContext, useContext, useMemo, useRef } from 'react';
import { Magic } from "magic-sdk";
import { isEqual } from 'lodash';

const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY); // Initialize Magic SDK

const UserContext = createContext();

// Export the userObject
export const userObject = {
  'james@aventine.ai': { userId: 1, access: {level:'admin',years:null}, userName: 'James', fullName: 'James Burns', organization: 'Aventine Properties' },
  'eileen@aventine.ai': { userId: 2, access: {level:'Training',years:null}, userName: 'Eileen', organization: 'Aventine Properties' },
  'maegan@aventine.ai': { userId: 3, access: {level:'aventine',years:null}, userName: 'Maegan', organization: 'Aventine Properties' },
  'oscar@aventine.ai': { userId: 4, access: {level:'Training',years:null}, userName: 'Oscar', organization: 'Aventine Properties' },
  'peter@aventine.ai': { userId: 5, access: {level:'Training',years:null}, userName: 'Peter', organization: 'Aventine Properties' },
  'claudia@aventine.ai': { userId: 6, access: {level:'Training',years:null}, userName: 'Claudia', organization: 'Aventine Properties' },
  'misbah@aventine.ai': { userId: 10, access: {level:'aventine',years:null}, userName: 'Misbah', organization: 'Aventine Properties' },
  'rogendy@aventine.ai': { userId: 12, access: {level:'aventine',years:null}, userName: 'Rogendy', organization: 'Aventine Properties' },
  'jrbmisand@optonline.net': { userId: 13, access: {level:'admin',years:null}, userName: 'Jim', organization: 'Propriety' },
  'max@aventine.ai': { userId: 14, access: {level:'Training',years:null}, userName: 'Max', organization: 'Aventine Properties' },
  
  // The propriety users
  'jrburns@aventine.ai': { userId: 16, access: {level:'admin',years:null}, userName: 'James Jr', fullName: 'James Burns', organization: 'Propriety' },
  'brennan@aventine.ai': { userId: 19, access: {level:'admin',years:null}, userName: 'Brennan', organization: 'Autism Unlimited LLC' },

  'lissette@aventine.ai': { userId: 20, access: {level:'Training',years:null}, userName: 'Lissette', organization: 'Aventine Properties' },
  'supandeep@aventine.ai': { userId: 21, access: {level:'Training',years:null}, userName: 'Supy', organization: 'Aventine Properties' },
  'christina@aventine.ai': { userId: 22, access: {level:'Training',years:null}, userName: 'Christina', organization: 'Aventine Properties' },
};


// W4600-000-000-09820-084
export const isUserAuthorized = (email, organization) => {
  // Check if the email exists in the userObject
  if (userObject[email]) {
    // Optionally, add organization-specific checks here if needed
    return true;
  }
  return false;
};

export const createUserInfo = (email) => {
  if (!userObject[email]) {
    return {
      email,
      userId: null,
      userAccess: false,
      userName: null,
      organization: null,
      // Default feature flags as binary values
      manualReview: 1,
      showSettledCases: 1,
      reviewedCasesFlag: 1,
      manualReviewOverride: 0,
      scarOnly: 0,
    };
  }

  const { userId, access, userName, organization } = userObject[email];

  return {
    email,
    userId: userId,
    userAccess: access,
    userName,
    organization,
    // Default feature flags as binary values
    manualReview: 1,
    showSettledCases: 1,
    reviewedCasesFlag: 1,
    manualReviewOverride: 0,
    scarOnly: 0,
  };
};

export const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState(() => {
    const storedUser = localStorage.getItem('userInfo');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true); // Prevent updates after unmounting

  useEffect(() => {
    const initializeAuth = async () => {
      const storedUser = localStorage.getItem('userInfo');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        if (!parsedUser.userAccess) {
          localStorage.removeItem('userInfo');
        } else if (!isEqual(parsedUser, userState)) {
          setUserState(parsedUser);
        }
      } else {
        const isLoggedIn = await magic.user.isLoggedIn();
        if (isLoggedIn) {
          const userInfo = await magic.user.getInfo();
          const newUser = createUserInfo(userInfo.email);
          if (isMounted.current && !isEqual(userState, newUser)) {
            setUserState(newUser);
            localStorage.setItem('userInfo', JSON.stringify(newUser));
          }
        }
      }
      if (isMounted.current) {
        setLoading(false);
      }
    };

    initializeAuth();

    return () => {
      isMounted.current = false; // Cleanup to avoid setting state on unmounted components
    };
  }, [userState]);

  const login = (userData) => {
    localStorage.setItem('userInfo', JSON.stringify(userData));
    setUserState((prevState) => (isEqual(prevState, userData) ? prevState : userData));
  };

  const logout = async () => {
    localStorage.removeItem('userInfo');
    setUserState(null);
    magic.user.logout();
  };

  const contextValue = useMemo(
    () => ({
      user: userState,
      loading,
      login,
      logout,
    }),
    [userState, loading]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import MuniSelectionV2 from './MuniSelectionV2'; // Ensure correct import path
import Button from '@mui/material/Button';
import {useUser} from '../UserContext'
import Container from '@mui/material/Container';
import AnalysisAccordion from './AnalysisAccordion';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosConfig'
import { usePersistedDataStore, useCompStore, useLocalDataStore} from '../store';
import CircularProgress from '@mui/material/CircularProgress';
import { isEqual } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNYData } from '../../common/NyDataObject';
import AdvancedSettings from './AdvancedSettings';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SettlementTable from './SettlementTable';
import { useNavigate } from 'react-router-dom';

// if this doesn't update on comp location change you have succeeded.
// this is getting a bit bloated with the addition of additional settings etc.

// FOR THE OPTIMIZATIONS, follow these practices. https://medium.com/@sagarpatil23399/efficient-react-you-might-not-need-useeffect-95decbf2dbae

// THis whole component is gross - the location params and state for params causes unnecessary double renders.
function CaseAnalysisContainer({courtDates, globalCompRef}) {
  const [runType, setRunType] = useState('all_clients');
  const userObject = JSON.parse(localStorage.getItem('userInfo'));
  // const {processStream, loading, compRef} = useStreamProcessorNew();
  const [additionalInput, setAdditionalInput] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('additionalInput') || '';
  });
  const getReportType = usePersistedDataStore((state) => state.reportType);
  const setReportType = usePersistedDataStore((state) => state.setReportType);
  const [propertyType, setPropertyType] = useState('residential');
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [courtDate, setCourtDate] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('courtDate') || null;
  });
  const [courtDateOptions, setCourtDateOptions] = useState([]);
  const [selectedCourtDate, setSelectedCourtDate] = useState(null);
  const [repID, setRepID] = useState('');
  const [activeDialog, setActiveDialog] = useState(null);
  const [error, setError] = useState({ county: null, municipality: null, village: null });
  const { user } = useUser();
  const isRestricted = user?.userAccess?.level !== 'admin' && user?.userAccess?.level !== 'aventine' && user?.userAccess?.level !== 'Training';
  const isAdmin = user?.userAccess?.level === 'admin';
  const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);
  const [reviewId, setReviewId] = useState(null);
  const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const setAdjustments = useCompStore((state)=> state.setAdjustments)
  const [dataGridRows, setDataGridRows] = useState([]);
  const setMarketingResponse = usePersistedDataStore((state) => state.setMarketingResponse);
  const navigate = useNavigate();
  const setTotalCases = usePersistedDataStore((state) => state.setTotalCases);
  const [advancedSettings, setAdvancedSettings] = useState(() => {
    const storedSettings = localStorage.getItem('advancedSettings');
    return storedSettings
      ? JSON.parse(storedSettings)
      : {
          manualReview: true,
          defaultRunTypes: ['settled'],
          compFilters: [],
          dateRange: [null, null],
          squareFeetFilter: 40,
          salePriceFilter: 40,
          acreageFilter: 40,
        };
  });
  // should the user overwrite manually saved comps?
  const [overwriteComps, setOverwriteComps] = useState(false);
  const [settlementData, setSettlementData] = useState(null);
  const setDataAnalysisResult = usePersistedDataStore((state) => state.setDataAnalysisResult);
  const setDataAnalysisColumns = usePersistedDataStore((state) => state.setDataAnalysisColumns);

  // Dynamically derive flags based on defaultRunTypes in settings
  const showSettledCases = advancedSettings?.defaultRunTypes?.includes('settled');
  const scarOnly = advancedSettings?.defaultRunTypes?.includes('scarFiled') || false;
  const manualReview = advancedSettings?.manualReview ?? true;
  const mlsComps = advancedSettings?.compFilters?.includes('mlsOnly') || false;
  const earliestDate = advancedSettings?.dateRange?.[0] || null;
  const latestDate = advancedSettings?.dateRange?.[1] || null;
  const reviewedCasesFlag = advancedSettings?.defaultRunTypes?.includes('reviewed') || false;
  const reportTypeRestricted = getReportType === 'settlement' || getReportType === 'data_analysis' || getReportType === 'marketing';

  // Initialize location params once during component mount
  const [locationParams, setLocationParams] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const initialParams = {
      state: params.get('state') || 'NY',
      county: params.get('county') || '',
      municipality: params.get('municipality') || '',
      village: params.get('village') || '',
      TaxYear: parseInt(params.get('TaxYear') || ''),
      MuniCode: params.get('MuniCode') || '',
      municipalityString: params.get('municipalityString'),
      reportType: params.get('reportType') || 'standard',
      runType: params.get('runType') || 'all_clients',
      additionalInput: params.get('additionalInput') || ''  // Add additionalInput to locationParams
    };

    // Set the initial states from URL params
    setReportType(params.get('reportType') || 'standard');
    setRunType(params.get('runType') || 'all_clients');
    setAdditionalInput(params.get('additionalInput') || '');

    // Handle settlement report type initialization
    if (initialParams.reportType === 'settlement') {
      initialParams.county = 'All';
      initialParams.municipality = 'All';
      initialParams.village = 'All';
      initialParams.MuniCode = 'All';
    }

    return initialParams;
  });

  // Consolidate location param updates
  const updateLocationParams = useCallback((updates) => {
    setLocationParams(prev => {
      // If county is being updated, ensure municipality is set to "All"
      const nextParams = { 
        ...prev, 
        ...updates,
        // When county changes, set municipality to "All" unless explicitly provided
        municipality: updates.county ? (updates.municipality || 'All') : (updates.municipality || prev.municipality)
      };
      return isEqual(prev, nextParams) ? prev : nextParams;
    });
  }, []);

  const { getCounties, getMunicipalities, getVillages } = useNYData();
  const { state, county, municipality, village, TaxYear } = locationParams;
  const counties = getCounties();
  const countyLookup = useMemo(
    () => Object.fromEntries(counties.map(({ key, selectVal }) => [selectVal, key])),
    [counties]
  );
  const municipalities = useMemo(() => {
    if (county) {
      // Use selectedCounty for dynamic lookup
      return getMunicipalities(countyLookup[county]) || [];
    }
    // Fallback to county if no selection
    return county ? getMunicipalities(countyLookup[county]) : [];
  }, [county, county, getMunicipalities, countyLookup, county]);
  
  const municipalityLookup = useMemo(
    () => Object.fromEntries(municipalities.map(({ key, selectVal }) => [selectVal, key])),
    [municipalities]
  );

  // Handle updating settings from AdvancedSettings component
  const handleSettingsConfirm = useCallback((newSettings) => {
    // Update state
    console.log('udpating settings',newSettings)
    setAdvancedSettings(newSettings);
    // Save to localStorage
    localStorage.setItem('advancedSettings', JSON.stringify(newSettings));
    // display successfully updated message.
    toast.success('user default settings updated successfully.');

    console.log('Updated settings saved:', newSettings);
  }, []);

  const handleRunTypeChange = (event) => {
    const newRunType = event.target.value;
    
    // Clear additional input and update URL
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('additionalInput');
    searchParams.delete('courtDate');
    searchParams.set('runType', newRunType);  // Add runType to URL params
    
    // Update URL without these params
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
    
    // Clear states
    setAdditionalInput('');
    setCourtDate(null);
    setSelectedCourtDate(null);
    setCourtDateOptions([]);
    
    if (runType === 'court_date' && error.courtDate) {
      setError((prev) => ({
        ...prev,
        courtDate: null,
      }));
    }

    if ((runType === 'single_pid' || runType === 'representative') && error.additionalInput) {
      setError((prev) => ({
        ...prev,
        additionalInput: null,
      }));
    }

    // Update both state and locationParams
    setRunType(newRunType);
    updateLocationParams({ runType: newRunType });
  };

  const clearError = useCallback(
    (key) => {
      if(error[key] && error[key]!==null){
      setError((prev) => ({
        ...prev,
        [key]: null,
      }));
    }
    },
    [setError, error]
  );

  const handleActiveDialog = (value) => setActiveDialog(value);
  const handleCloseDialog = () => setActiveDialog(null);

  // UPDAT ETHIS CODE SO THAT IF THERE IS A SINGLE PID, don't require other params.
  // Some closure issue here
  const handleDataAnalysis = async (params) => {
    try {
      setIsFetching(true);

      // extract every county code into an array and pass into backend
      // extract the selectval codes from the counties object
      const countyCodes = counties.map(county => county.selectVal);
      const response = await axiosInstance.post('/flag_bad_data', {
        MuniCode: countyCodes,
        CountyCodes: countyCodes,
        TaxYear: params.TaxYear,
        county: params.county,
        municipality: params.municipality,
        village: params.village,
        villageRun: village !== 'All' ? 1 : 0,
        propertyType: params.propertyType,
        UserId: userObject?.userId || null, // this field is used for training users to select their comps
        TrainingUser: userObject?.userAccess?.level === 'Training' ? 1 : 0,
        reportType: getReportType, // ^
      });
      console.log('response from flag bad data',response)

      // Set the response data
      setDataAnalysisResult(response.data.records);
      // Set columns based on the keys of the first record
      if (response.data.records && response.data.records.length > 0) {
        const columns = Object.keys(response.data.records[0]).map(key => ({
          field: key,
          headerName: key.replace(/([A-Z])/g, ' $1').trim(), // Convert camelCase to Title Case
          flex: 1,
          minWidth: 130
        }));
        setDataAnalysisColumns(columns);
      }

      toast.success('Data analysis completed successfully');

      // Get current search params and navigate
      const searchParams = new URLSearchParams(window.location.search);
      setCompSheet([])
      // update negotiation obj with you rreport type and municode etc
      setNegotiationObj({
        reportType: 'data_analysis',
        MuniCode: params.MuniCode,
        TaxYear: params.TaxYear,
        county: params.county,
        municipality: params.municipality,
        village: params.village,
      })
      navigate(`/analysis/1?${searchParams}`);

    } catch (error) {
      console.error('Error in data analysis:', error);
      toast.error('Failed to complete data analysis');
      setDataAnalysisResult([])
    } finally {
      setIsFetching(false);
    }
  };

  // Add current tax year calculation at the top
  const currentTaxYear = useMemo(() => new Date().getFullYear() - 1, []);

  const runAnalysis = useCallback(
    async ({ accordionId = null, force = false, overwriteComps: overwriteOverride = overwriteComps } = {}) => {
      console.log('Running analysis with overwriteComps:', overwriteOverride);
  
      setError({});

      // Validation rules based on report type and run type
      const validateLocation = () => {
        // For settlement runs, only validate county if doing specific area
        // if (getReportType === 'settlement') {
        //   // if there is no year in the settlement run, require it
        //   if (!locationParams.TaxYear) {
        //     setError(prev => ({ ...prev, TaxYear: 'Select a tax year.' }));
        //     return false;
        //   }
        //   // For 'all_clients' type, no location validation needed
        //   return true;
        // }

        // For non-settlement runs, validate both county and municipality
        if (getReportType !== 'settlement') {
          if (!locationParams.county) {
            setError(prev => ({ ...prev, county: 'Select a county.' }));
            return false;
          }
          
          if (!locationParams.municipality && locationParams.county !== 'All') {
            setError(prev => ({ ...prev, municipality: 'Select a municipality.' }));
            return false;
          }

          if (!locationParams.TaxYear) {
            setError(prev => ({ ...prev, TaxYear: 'Select a tax year.' }));
            return false;
          }
        }

        

        return true;
      };

      // Run validations
      if (!validateLocation()) return;
  
      // Skip large query warning for settlement and data_analysis report types
      if (runType === 'all_clients' && 
          getReportType !== 'settlement' && 
          getReportType !== 'data_analysis' && 
          !force && 
          (locationParams.county === 'All' || locationParams.municipality === 'All')) {
        setActiveDialog('largeQueryWarning');
        return;
      }
  
      if (runType === 'court_date' && !courtDate) {
        setError((prev) => ({
          ...prev,
          courtDate: 'Select a court date.',
        }));
        return;
      }
  
      if ((runType === 'single_parcel' || runType === 'representative') && !additionalInput) {
        const errorString = runType === 'single_parcel' ? 'Parcel ID' : 'Rep ID';
        setError((prev) => ({
          ...prev,
          additionalInput: `Enter a valid ${errorString}`,
        }));
        return;
      }
  
      // console.log('force status', manualReview);
      if (!force && !manualReview) {
        setActiveDialog('manualReviewWarning');
        return;
      }
  
      setIsFetching(true);

      if (getReportType === 'data_analysis') {
        return handleDataAnalysis({
          MuniCode: locationParams.MuniCode,
          TaxYear: locationParams.TaxYear,
          county: locationParams.county,
          municipality: locationParams.municipality,
          village: locationParams.village,
          propertyType
        });
      }

      if(getReportType === 'settlement'){
        try {
          // Use current tax year if none provided
          const taxYear = locationParams.TaxYear || currentTaxYear;
          
          const settlementParams = { 
            TaxYear: taxYear,
            CourtDate: courtDate
          };
          
          // Only add MuniCode for specific area runs
          if (runType === 'specific_area' && locationParams.MuniCode) {
            settlementParams.MuniCode = locationParams.MuniCode;
          }
          
          const settlementResponse = await axiosInstance.post(`/load_settlements`, settlementParams);
          
          // set the taxyear to the current tax year
          // setLocationParams((prev) => ({
          //   ...prev,
          //   TaxYear: taxYear,
          // }));
          // update the URL params with the current tax year
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('TaxYear', taxYear.toString());
          setSettlementData(settlementResponse.data);
          setIsFetching(false);

          // This doesn't go to the /1 anymore
          navigate(`${window.location.pathname}?${searchParams.toString()}`);

          return;
        } catch (error) {
          console.error('Error fetching settlements:', error);
          toast.error('Failed to load settlement data');
          setIsFetching(false);
          return;
        }
      }
  
      // Safely handle cases where `accordionId` is not passed in
      if (accordionId) {
        setExpandedAccordion(accordionId);
      }
  
      const params = {
        runType, // all_clients, court_date, single_parcel, representative
        propertyType, // ex: residential, commercial, industrial
        getReportType,
        county: locationParams.county,
        municipality: locationParams.municipality,
        village: locationParams.village,
        TaxYear: parseInt(locationParams.TaxYear),
        MuniCode: locationParams.MuniCode,
        courtDate,
        overwriteComps: overwriteOverride,
        manualReview, // if false, save the comps automatically
        singlePid: additionalInput, // renamed for appropriate flag
      };
  
      // Example response simulation
      const firstResult = { id: 1, name: locationParams.municipalityString, value: 'Example Value' };
      setDataGridRows([firstResult]);
      setCompSheet([]);
      setOptimizedComps([]);
      setMarketingResponse([]);
      setTotalCases(null);
  
      // console.log('is it mls only filter');
      // console.log(advancedSettings.compFilters.includes('mlsOnly'));
  
      // Trigger initialization fetch
      const response = await handleInitializeFetch(params);
    },
    [
      runType,
      advancedSettings,
      locationParams,
      courtDate,
      additionalInput,
      propertyType,
      getReportType,
      overwriteComps,
      manualReview,
      handleDataAnalysis,
      currentTaxYear
    ]
  );
  

  // Here begins the retarded old logic:
  // refactored the initialization, removed adjustment load.
  async function loadClientIds(muniObj) {
    console.log('Object going into load client IDs:', muniObj);
    // if the user is a training user, then set the TrainingUser to 1, otherwise set it to 0.
    // then the report type should be set to training
    
    try {
        const response = await axiosInstance.post(`/load_client_ids`, muniObj);
        const [clientList, negotiationCases] = response.data;
        // console.log('Client List:', clientList);
        // console.log('Negotiation Cases:', negotiationCases);
        return [clientList, negotiationCases]; // Return the parsed data
    } catch (err) {
        console.error('Error in loadClientIds:', err);
        // Pass a descriptive error message to be handled upstream
        throw new Error(err.response?.data?.message || 'Failed to load client IDs.');
    }
}


  // use this for the manual review false saving logic, otherwise rest should disappear.
  // the streaming update to this function removed the helper function entirely. TBD if that is desired behavior or not.
  const handleInitializeFetch = async (params, manualOverride = false) => {
    const {MuniCode, village, TaxYear, courtDate, runType, singlePid} = params;
    
    // Get the current search params to extract the string values

    // here, set the sarchparams to have comp =1
    const searchParams = new URLSearchParams(window.location.search);
    const countyString = searchParams.get('countyString') || 'All';
    const muniString = searchParams.get('municipalityString') || 'All';
    if (!searchParams.has('comp') || searchParams.get('comp') !== '1') {
      searchParams.set('comp', '1');
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }

    console.log('initialization params',params)
    let formattedCourtDate = null;
    // format the courtdate (confirm this is still necessary)
    if(courtDate){
      formattedCourtDate = courtDate
        ? new Date(new Date(courtDate).getTime() - new Date(courtDate).getTimezoneOffset() * 60000)
              .toISOString()
              .split('T')[0]
        : '';
        console.log('formatted court date',formattedCourtDate)
    }

    // reset your compref
    globalCompRef.current = {
        properties: [],
        cases: [],
        totalCases: 0,
    };

    // need to revisit this once settings are done - have it update these the user context level.
    // Need to load these in from the localstorage if they exist here.
    let updateNegotiationObj = {
        MuniCode,
        TaxYear,
        countyString,  // Add county string
        muniString,    // Add municipality string
        ...(formattedCourtDate ? { CourtDate: formattedCourtDate, CourtRun: 1 } : { CourtRun: 0 }),
        showSettledCases: showSettledCases,
        manualReview: manualReview,
        mlsCompsOnly: mlsComps,
        earliestCutoffDate: earliestDate,
        latestCutoffDate: latestDate,
        reportType: getReportType,
        villageRun: village !== 'All' ? 1 : 0,
        TrainingUser: userObject?.userAccess?.level === 'Training' ? 1 : 0,
        UserId: userObject?.userId || null,
        manualReviewOverride: params.overwriteComps,
        scarOnly: scarOnly,
        reviewedCasesFlag: reviewedCasesFlag,
        RepId: repID,
    };

    // this is wholly rendundant fromt he above i think
    if (courtDate === '' || courtDate === null) {
        delete updateNegotiationObj.CourtDate;
    }

    try {
        // If you put in a single specific PID to run ()
        let updatedCases = []; // i think this is no longer relevant
        let negotiationCases = [];
        if (singlePid) {
            console.log('Running a single PID');
            console.log(singlePid)
            // updateNegotiationObj.cases = [singlePid];
            negotiationCases = [singlePid];
            updateNegotiationObj.MuniCode = singlePid.slice(0, 3); // Update MuniCode from PID
            updateNegotiationObj.overrideIds = [singlePid]; // Update overrideIds from PID
        } 
        // else {
            // Generic fetch for all cases
            console.log('pre load ids negoitiation obj',updateNegotiationObj)
            const clientIdResponse = await loadClientIds(updateNegotiationObj);
            console.log('client id response',clientIdResponse)
            // Temporary get adjustments
            const loadAdjustmentsResponse = await axiosInstance.post(`/load_adjustments`, updateNegotiationObj);
            let adjustments = null;
            const adjustmentResponse = loadAdjustmentsResponse.data;
            adjustments = adjustmentResponse.response;
            // console.log('client id response',clientIdResponse)
            updatedCases = clientIdResponse[0];
            negotiationCases = clientIdResponse[1];
            // console.log('updated cases',negotiationCases)
            
            // you could filter here for cases that are settled, unsettled or SCAR
            // if scarOnly === true, then filter for the updatedCases with SCARFiled === 1 only
            // filter so that you only include teh UIDS for cases which are scar filed if that setting is chosen.
            if(scarOnly && updateNegotiationObj.reportType !== 'marketing'){
              negotiationCases = negotiationCases.filter((caseItem) => caseItem.SCARFiled === 1);
              // Step 2: Filter updatedCases to only include cases where parcel_id matches a negotiationCase parcel_id
              const validParcelIds = new Set(negotiationCases.map((caseItem) => caseItem.parcel_id));

              // Step 3: Filter updatedCases to include only strings that exist in validParcelIds
              updatedCases = updatedCases.filter((caseId) => validParcelIds.has(caseId));
            }

            // perform frontend filtering for settled cases
            if (!showSettledCases && updateNegotiationObj.reportType !== 'marketing') {
              console.log('performing filtering for showsettledcases')
              negotiationCases = negotiationCases.filter((caseItem) => {
              // Settled field based on SCAR or BAR logic
              const settled = ['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(caseItem.SCARDeterminationAction)

                if (caseItem.SCARFiled === 1) {
                  // Include cases without SCARDeterminationValue
                  return !settled
                } else if (caseItem.BARFiled === 1) {
                  // Include cases without BARDeterminationValue
                  return !caseItem.BARDeterminationValue || caseItem.BARDeterminationValue === '';
                } else {
                  console.log('running third loop')
                  // If BARFiled is 0, blank string, or undefined, don't filter out
                  return caseItem.BARFiled === 0 || caseItem.BARFiled === '' || caseItem.BARFiled === undefined;
                }
              });
                console.log("After Settled Cases filtering:", negotiationCases);
            
                // Step 2: Update validParcelIds and updatedCases to match remaining negotiationCases
                console.log(updatedCases)
                const validParcelIds = new Set(negotiationCases.map((caseItem) => caseItem.parcel_id));
              //   updatedCases.forEach((caseId, index) => {
              //     // console.log(`Updated Case [${index}]:`, caseId, typeof caseId, validParcelIds.has(caseId));
              // });
              
              // Filter updatedCases based on validParcelIds
              updatedCases = updatedCases.filter((caseId) => {
                  // console.log('Checking caseId:', caseId, 'Exists in validParcelIds:', validParcelIds.has(caseId));
                  return validParcelIds.has(caseId);
              });
                // console.log("Updated Cases after Settled Cases filter:", updatedCases);
            }

            // perform if showSettledCases filtering:
            // Next, if the case is settled, remove any which have SCARDeterminationValue (or if SCARFiled === 0, if they have a BARDeterminationValue)

            if (updatedCases.length === 0 && updateNegotiationObj.reportType !== 'marketing') {
                console.log('No client IDs found, aborting fetch.');
                // reimplement this with your new settings

                // also display the "no scar clients found" toast, if scarOnly is true, say no "SCAR filed" 
                // scarOnly
                toast.error(`No ${!showSettledCases ? 'unsettled ' : scarOnly ? 'SCAR filed ' : ''}clients found in this area for a ${updateNegotiationObj.reportType} run.`, {
                  autoClose: false,      // Prevents automatic dismissal
                  closeOnClick: true,    // Allows user to click to dismiss
                  draggable: true,       // Optional: allows dragging to close
                  // pauseOnHover: true,    // Optional: keeps toast open when hovering
              });
                setIsFetching(false);
                return;
            }

            const updatedNegotiationObj = { ...updateNegotiationObj, uids: updatedCases, cases: negotiationCases };
        console.log('Updated negotiation object:', updatedNegotiationObj);
        
        // here set your negotiation object -- no need to load settlements anymore
        // updatedNegotiationObj.cases = negotiationCases;
        setNegotiationObj(updatedNegotiationObj);
        setAdjustments(adjustments);
        setReviewPage(true);
        setReviewId(1)
        
        const searchParams = window.location.search; // Get current search params
        // Hardocded this for now to be equal to 1
        navigate(`/analysis/1${searchParams}`);
    } catch (error) {
      console.error('Error during fetch:', error);
      setIsFetching(false);
      toast.error(`Error: ${error.message}`, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: true,
          draggable: true,
      });
  } finally {
      console.log('KNN query is finished');
  }
  };

  const handleDialogClose = () => setActiveDialog(null);

  const handleDialogConfirm = (action) => {

    // when dxpancding to other run types, update the accordion ID type to be dyanmically set.
    if (activeDialog === 'manualReviewWarning') {
      const shouldOverwrite = action === 'overwrite';
      setOverwriteComps(shouldOverwrite); // Update the state asynchronously
      console.log('Overwrite Comps:', shouldOverwrite);
      runAnalysis({ accordionId: 'case', force: true, overwriteComps: shouldOverwrite });
    }
    if (activeDialog === 'largeQueryWarning') {
      runAnalysis({accordionId:'case', force:true}); // Force run analysis
    }
    handleDialogClose();
  };

  // const handleConfirmationClose = () => setConfirmationOpen(false);
  

  // Need to update this to return the muni string form this backend route to complete this flow fully.
  const handleSetCourtDateOptions = async (courtDate) => {
    setCourtDate(courtDate);
    
    // Update URL with court date
    const searchParams = new URLSearchParams(window.location.search);
    if (courtDate) {
      searchParams.set('courtDate', courtDate);
    } else {
      searchParams.delete('courtDate');
    }
    
    // Update URL
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
    
    const response = await axiosInstance.post(`/load_court_munis`, { CourtDate: courtDate });
    setCourtDateOptions(response.data);

    // Only update location params immediately if there's exactly one option
    if (response.data.length === 1) {
      const county = response.data[0].muni.slice(0, 1);
      const municipality = response.data[0].muni.slice(1, 3);
      const countyString = countyLookup[county] || 'Unknown County';
      const municipalityLookup = Object.fromEntries(getMunicipalities(countyLookup[county]).map(({ key, selectVal }) => [selectVal, key]))
      const municipalityString = municipalityLookup[municipality] || 'Unknown Municipality';
      const muniCode = response.data[0].muni;
      const TaxYear = response.data[0].TaxYear;
      
      const updates = { county, municipality, MuniCode: muniCode, countyString, municipalityString, TaxYear };
      Object.entries(updates).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      updateLocationParams(updates);
    } else if (response.data.length > 1) {
      setActiveDialog('courtDateSelection');
    }
  }

  const handleAdditionalInputChange = useCallback((input) => {
    // Update both state and locationParams
    setAdditionalInput(input);
    updateLocationParams({ additionalInput: input });
    
    // Update URL with additional input
    const searchParams = new URLSearchParams(window.location.search);
    if (input) {
      searchParams.set('additionalInput', input);
    } else {
      searchParams.delete('additionalInput');
    }
    
    if (input?.length > 5) {
      const county = input.slice(0, 1);
      const municipality = input.slice(1, 3);
      const muniCode = input.slice(0, 3);
      
      // Get the county name from lookup
      const countyObj = counties.find(c => c.selectVal === county);
      const countyString = countyObj?.key || 'Unknown County';

      // Get municipality name from lookup
      const municipalityList = getMunicipalities(countyString) || [];
      const muniObj = municipalityList.find(m => m.selectVal === municipality);
      const municipalityString = muniObj?.key || 'Unknown Municipality';

      // Update all necessary parameters including the string values
      const updates = { 
        county, 
        municipality, 
        MuniCode: muniCode, 
        countyString, 
        municipalityString,
        additionalInput: input,  // Include additionalInput in updates
        // Clear village-related params when updating municipality
        village: '',
        villageString: '',
      };

      // Update URL params
      Object.entries(updates).forEach(([key, value]) => {
        if (value) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      });

      // Update URL
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      // Update location params state with all necessary values
      updateLocationParams(updates);
    }
  }, [updateLocationParams, counties, getMunicipalities]);

  // Modify the report type onChange handler
  const handleReportTypeChange = (event) => {
    const newReportType = event.target.value;
    setReportType(newReportType);
    
    // Clear additional input, court date and update URL
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('additionalInput');
    searchParams.delete('courtDate');
    
    // Force runType to 'all_clients' for settlement, data_analysis, and marketing reports
    if (newReportType === 'settlement' || newReportType === 'data_analysis' || newReportType === 'marketing') {
      const newRunType = 'all_clients';
      setRunType(newRunType);
      updateLocationParams({ runType: newRunType });
      searchParams.set('runType', newRunType);
      setAdditionalInput('');
      setCourtDate(null);
      setSelectedCourtDate(null);
      setCourtDateOptions([]);
    }
    
    // Set county and municipality to "All" for settlement report type
    if (newReportType === 'settlement') {
      updateLocationParams({
        county: 'All',
        municipality: 'All',
        reportType: newReportType
      });
      searchParams.set('county', 'All');
      searchParams.set('municipality', 'All');
      searchParams.set('MuniCode', 'All');
    } else {
      updateLocationParams({ reportType: newReportType });
    }
    
    // Update URL params
    searchParams.set('reportType', newReportType);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  // Define the width (max) of the thing you are passing in.
  // Need to make court date selection smaller.
  const fields = [
    {
      type: 'select',
      label: 'Property Type',
      value: propertyType,
      width: '140px',
      onChange: (event) => setPropertyType(event.target.value),
      options: [
        { label: 'Residential', value: 'residential' },
        { label: 'Commercial', value: 'commercial', disabled: true },
        { label: 'Industrial', value: 'industrial', disabled: true },
      ],
    },
    {
      type: 'select',
      label: 'Report',
      value: getReportType,
      width: '140px',
      onChange: handleReportTypeChange,
      options: [
        { label: 'Sales Analysis', value: 'standard' },
        { label: 'Assessment Analysis', value: 'assessment' },
        ...(isAdmin ? [
          // Move the settlement and data analysis options into the below accordion, and call it "Data Analysis"
          { label: 'Settlement Analysis', value: 'settlement' },
          { label: 'Data Analysis', value: 'data_analysis' },
          { label: 'Marketing Analysis', value: 'marketing', disabled: !isAdmin },
        ] : []),
      ],
    },
    {
      type: 'select',
      label: 'Run Type',
      value: locationParams.runType,
      onChange: handleRunTypeChange,
      width: '140px',
      options: [
        { label: 'All Clients', value: 'all_clients' },
        { label: 'Court Date', value: 'court_date', disabled: isRestricted || reportTypeRestricted },
        { label: 'Single Parcel', value: 'single_parcel', disabled: isRestricted || reportTypeRestricted },
        { label: 'Representative', value: 'representative', disabled: true },
      ],
    },
    ...(runType === 'court_date'
      ? [
          {
            type: 'select',
            label: 'Court Date',
            value: courtDate,
            onChange: (event) => {
              if (error.courtDate) {
                setError((prev) => ({ ...prev, courtDate: null })); // Reset courtDate error
              }
              handleSetCourtDateOptions(event.target.value);
            },
            options: courtDates,
            error: error.courtDate,
            width: '140px',
          },
        ]
      : []),

      // I'm not happy with how this works - as it doesnt keep the prior inputs fixe don the screen and shifts them.
    ...(runType === 'single_parcel' || runType === 'representative' || runType === 'all_clients'
      ? [
          {
            type: 'text',
            label: runType === 'single_parcel' ? 'Parcel ID' : 'Rep ID',
            value: locationParams.additionalInput,
            width: runType === 'single_parcel' ? '270px' : '140px',
            visible: runType === 'all_clients' ? false : true,
            error: error.additionalInput,
            onChange: (event) => {
              if (error.additionalInput) {
                setError((prev) => ({ ...prev, additionalInput: null }));
              }
              // Trim leading spaces from the input value
              const trimmedValue = event.target.value.trimStart();
              handleAdditionalInputChange(trimmedValue);
            },
          },
        ]
      : []),
  ];

  const analysisButtons = [
    {
      type: 'icon',
      tooltip: 'Advanced Settings',
      onClick: () => handleActiveDialog('settings'),
    },
    {
      type: 'button',
      variant: 'contained',
      color: 'primary',
      label: 'Run',
      onClick: () => runAnalysis({ accordionId: 'case' }), // define which one to be opening
      disabled: getIsFetching, // Disable button when fetching
      endIcon: getIsFetching ? <CircularProgress size={16} sx={{
        color: 'white', // Force white color
      }}/> : null, // Show progress icon when fetching
    },
  ];

  // make sure you keep a stable ref for this & memoize the rows
  const dataGridProps = useMemo(
    () => ({
      columns: [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          renderCell: (params) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center', // Vertically center content
                gap: 1,
                height: '100%', // Ensures proper alignment in the cell
                overflow: 'hidden', // Prevents overflow issues
              }}
            >
              <Typography
                sx={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // here, call the 
                  // setReviewId(params.id)
                  setReviewPage(true);
                  setReviewId(1)
                }
                }
              >
                {params.value}
              </Typography>
              {getIsFetching && <CircularProgress size={16} />}
            </Box>
          ),
        },
        { field: 'value', headerName: 'Value', width: 150 },
      ],
      rows: dataGridRows,
      localeText: { noRowsLabel: 'Run Analysis to see results' },
      pageSizeOptions: [5, 10, 20],
      disableColumnMenu: true,
      disableSelectionOnClick: true,
    }),
    [getIsFetching, dataGridRows]
  );

  // Programatic navigation to the next summary table screen.
  // if (reviewId !== null && getIsFetching) {
  //   // Render Navigate to redirect when reviewId is set
  //   const searchParams = window.location.search; // Get current search params
  //   return <Navigate to={`/analysis/${reviewId}${searchParams}`} />;
  // }

  return  (
    <div className='w-full h-full flex flex-col'>
    <Container
      maxWidth="xl" // Options: 'xs', 'sm', 'md', 'lg', 'xl' or false for no max width
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        py: 2,
      }}
    >

      {getReportType === 'settlement' && settlementData ? (
          <SettlementTable 
            data={settlementData[locationParams.TaxYear || currentTaxYear]}
            multiYearData={settlementData}
            selectedYear={parseInt(locationParams.TaxYear || currentTaxYear)}
            onBack={() => {
              setSettlementData(null);
              setExpandedAccordion(null);
            }}
            taxYear={locationParams.TaxYear || currentTaxYear}
          />
        ) : (
          <>

      {/* The muni-multiselect (sets params) */}
      <MuniSelectionV2
        error={error}
        handleClearError={clearError}
        locationParams={locationParams}
        updateLocationParams={updateLocationParams}
        // defaultTaxYear={defaultTaxYear}
      />

      {/* move these cards to their own component */}
      {/* top display cards (most important stats for area): */}
    
      {/* Case Analysis container (finally, lol) */}
      <AnalysisAccordion
        title="Case Analysis"
        fields={fields}
        buttons={analysisButtons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'case'}
        onExpandToggle={() =>
          setExpandedAccordion(expandedAccordion === 'case' ? null : 'case')
        }
      >
      </AnalysisAccordion>

      {/* Equity Analysis container - UNUSED */}
      <AnalysisAccordion
        title="Data Analysis"
        fields={[]}
        buttons={analysisButtons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'data'}
        disabled={true}
        onExpandToggle={() =>
          // setExpandedAccordion(false)
          console.log('not ready yet')
        }
        tooltip='Coming Soon!'
      >
      </AnalysisAccordion>

      <Dialog open={!!activeDialog} onClose={handleDialogClose}>
          {activeDialog === 'manualReviewWarning' && (
            <>
              <DialogTitle>Manual Review Disabled
                {/* Close Icon */}
                <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  Manual review is disabled. Would you like to overwrite previously manually saved comps or keep them?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDialogConfirm('keep')}>Keep Comps</Button>
                <Button onClick={() => handleDialogConfirm('overwrite')} variant="contained" color="primary">
                  Overwrite Comps
                </Button>
              </DialogActions>
            </>
          )}

          {activeDialog === 'largeQueryWarning' && (
            <>
              <DialogTitle>Large Query Warning
                 {/* Close Icon */}
                 <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography>This query may take a long time to process. Are you sure you want to proceed?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={() => handleDialogConfirm()} variant="contained" color="primary">
                  Run Anyway
                </Button>
              </DialogActions>
            </>
          )}

          {activeDialog === 'courtDateSelection' && (
            <>
              <DialogTitle>Municipality Selection
                <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2" gutterBottom>
                  Please select which municipality you would like to run.
                </Typography>
                <Select
                  value={selectedCourtDate || ''}
                  onChange={(event) => {
                    const selectedMuni = event.target.value;
                    setSelectedCourtDate(selectedMuni);
                    
                    // Find the matching court date option
                    const selectedOption = courtDateOptions.find(option => option.muni === selectedMuni);
                    
                    if (selectedOption) {
                      const county = selectedOption.muni.slice(0, 1);
                      const municipality = selectedOption.muni.slice(1, 3);
                      
                      // Get the county name from lookup
                      const countyObj = counties.find(c => c.selectVal === county);
                      const countyString = countyObj?.key || 'Unknown County';
                      
                      // Get municipality name from lookup
                      const municipalityList = getMunicipalities(countyString) || [];
                      const muniObj = municipalityList.find(m => m.selectVal === municipality);
                      const municipalityString = muniObj?.key || 'Unknown Municipality';
                      
                      // Update URL params
                      const searchParams = new URLSearchParams(window.location.search);
                      const updates = {
                        county,
                        municipality,
                        MuniCode: selectedOption.muni,
                        TaxYear: selectedOption.TaxYear,
                        countyString,
                        municipalityString,
                        village: '',
                        villageString: '',
                      };
                      
                      Object.entries(updates).forEach(([key, value]) => {
                        if (value) {
                          searchParams.set(key, value);
                        } else {
                          searchParams.delete(key);
                        }
                      });
                      
                      // Update URL
                      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
                      window.history.replaceState(null, '', newUrl);
                      
                      // Update location params
                      updateLocationParams(updates);
                    }
                  }}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select a Municipality
                  </MenuItem>
                  {courtDateOptions.map((option, index) => {
                    const county = option.muni.slice(0, 1);
                    const municipality = option.muni.slice(1, 3);
                    
                    // Get the county name from lookup
                    const countyObj = counties.find(c => c.selectVal === county);
                    const countyString = countyObj?.key || 'Unknown County';
                    
                    // Get municipality name from lookup
                    const municipalityList = getMunicipalities(countyString) || [];
                    const muniObj = municipalityList.find(m => m.selectVal === municipality);
                    const municipalityString = muniObj?.key || 'Unknown Municipality';

                    return (
                      <MenuItem key={index} value={option.muni}>
                        {`${municipalityString} - (${option.muni})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Close</Button>
                <Button
                  onClick={() => {
                    console.log('Run with selected option:', selectedCourtDate);
                    handleDialogClose();
                    runAnalysis({accordionId:'case', force: true });
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!selectedCourtDate || selectedCourtDate === ''}
                >
                  Run
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

      <AdvancedSettings
        open={activeDialog === 'settings'}
        onClose={handleCloseDialog}
        onConfirm={handleSettingsConfirm}
        initialSettings={advancedSettings}
        runAnalysis={runAnalysis}
      />

      </>
      )}
  </Container>
  </div>
  );
}

CaseAnalysisContainer.whyDidYouRender = true;
export default CaseAnalysisContainer;
